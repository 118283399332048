/*
	This is a parent class to fields stored in the database.

	Mostly it contains a type conversion routines: as data is passed between the DB, the
	server, the client and the user, various conversions are required. These are centralised in these classes.

	A PRINCIPLE FOLLOWED HERE:
	Data should be kept in its most ideal JS form whenever possible and for as long as possible.
	This means, for example, that on reading a date out of the database it should be immediately 
	converted to a Date, and not left as a string.  This should allow us to use the date methods
	whenever we want to without conversions and minimize the number of conversions.
 */

import FieldTypeException from './FieldTypeException';

class DbFieldType
{
//XXX maybe add name

    constructor()
    {
        this.required = true;
    }

    /* 
        To be used as part of a chain during object construction.
        Note that the first object must be new as it is modified here.
     */
    optional()
    {
        this.required = false;
        return this;
    }

	/* The column type */
	sqlType() { }

	/*
		Converts a string to the field's preferred JS type.  Some smarts can be included in the parsing
		process, but it shouldn't do anything surprising to the user.

		Type checking should be left to validate() which should be called immediately after this function.
		If an type error must be thrown, use A FieldTypeException.
	 */
	fromUserString(value) 
	{
		throw new Error('Not implemented');
	}

	/* 
		'value' should be field data mapped to its preferred JS type - which will often be a string,
		number, boolean, maybe Date, ...  I don't in general expect specific classes to be created for field
		types.  NB specific types would be harder to serialise whereas the JS ones can generally use JSON.

		If the value of the wrong type or is otherwise invalid, this function should return with a message 
        suitable to be shown to the user. If the field is OK the function should return null.
	 */
	validate(value) 
    {
        if (value==null && this.required)
            return 'Field is required';
        return null;
    }


	/*
//XXX NB it is possible Sqlite and MySQL will have differences. 
A pretty good solution would be to add 2 extra functions here "jsToMysql" and "mysqlToJs".
Set it by default to equal jsToSql and sqlToJs.

		Takes the field value in its preferred JS type and converts it to an SQL field type.
		We can assume the JS value has already been validated.
		This function should not need to throw errors - this is the job of validate().

Q should we precede this call on the server with a call to validate()?
  sanitiseJs generate errors though.  Also cleaning up input 2 (or more) times may be a bit weird...
	 */
	jsToSql(value) 
	{
		/* 
			Sometimes the DB adapter will provide a decent conversion routine. I choose to
			rely on it where possible and so return the value by default rather than convert
			to a string.
		 */
		return value;
	}

	sqlToJs(value)
	{
		return value;
	}

	/* 
		This is a convenience function for callers and is not to be overridden.
		Throws: FieldTypeException
	 */
	getValidated(str,fieldName)
	{
		const value = this.fromUserString(str);
		const ret = this.validate(value);
        if (ret!=null)
            throw new FieldTypeException(ret,fieldName);
		return value;
	}

    /* The data to be passed to the template so that field can be displayed in "view" mode. */
    viewData(value,env)
    {
        return value;
    }
}

export default DbFieldType;
