import DbTables from './DbTables';
import DbId from './DbId';

/* This is an abstract parent class */
class ConnectionToServer
{
	constructor()
	{
		this.visiblePage = null; 
		this.listeningPages = [];
		this.init = this.init.bind(this);
		this.registerListeningPage = this.registerListeningPage.bind(this);
	}

	/* --- Abstract functions: --- */

	init() { throw new Error('Not implemented'); }

	readRow(rootTable,rootId,joins) { throw new Error('Not implemented'); }

	query(queryName,params) { throw new Error('Not implemented'); }

	/* Clients generally shouldn't call this function directly */
    slowPerformTransaction(transaction) { throw new Error('Not implemented'); }

	/* For testing PretendDB SQL queries in browser TODO disable in production */
	getDb() { }

	
	/* --- Public functions: --- */

	/* 
		Note that undisplayed pages in the current window are updated directly from here, and not via
		the server. This is faster, makes it easier to exclude the current page from receiving a DB update 
		that it triggered.
	 */
	handleDbUpdates(isSameWindow,ev)
	{
		for (var page of this.listeningPages)
			page.handleDbUpdates(this.visiblePage==page,ev.updates);
	}

//TODO should create UnitTest for this DB update and GUI sync stuff
	updateOtherPages(updates)
	{
		this.handleDbUpdates(true, {id:'sameWindow',updates:updates});
	}

	changeVisiblePage(page)
	{
		/* There is only one visible page per tab. changeVisiblePage() must be called every time render() is called. */
		this.visiblePage = page;
			
	}

	registerListeningPage(page)
	{
		this.listeningPages.push(page);
	}

	invalidateListeningPages()
	{
        for (let page of this.listeningPages)
			//XXX Is page.components really public - cf providing a get function
            for (let component of page.components)
                component.setNeedsLoad();

	}

	/* Note that these are not implemented as single DB "transactions"  */
	startTransaction()
	{
		this.transaction = [];
	}

	storeCommand(command)
	{
		this.transaction.push(command); 
	}

	finishTransaction()
	{
		return this.slowPerformTransaction(this.transaction)
			.then(([retVariables,changes]) => {
				this.updateOtherPages(changes);
                // I've swapped changes & retVariables around.  It's probably simpler to just uses changes than patch retVariables in 
				return Promise.resolve(changes,retVariables);
			});
	}

	/* This case is well suited for optimistic updates */
	finishTransactionNoReturnValues()
	{
		this.updateOtherPages(this.transaction);
		this.slowPerformTransaction(this.transaction);
	}

	/* The ID of the new variable is placed in idVar for use by other operations in the transaction */
	createRow(table,fields,idVar)
	{
		const row = checkAllFieldInputs(true,table,fields);
		const command = {operation:'CREATE_ROW',table:table,row:row,idVariable:idVar};
		this.storeCommand(command);
	}

	updateRow(table,fields)
	{
		const row = checkAllFieldInputs(false,table,fields);
		const command = {operation:'UPDATE_ROW',table:table,id:row.id,row:row};
		this.storeCommand(command);
	}

	/* Performs a cascade delete through the owned tables */
	deleteRow(table,id)
	{
		const command = {operation:'DELETE_ROW',table:table,id:id};
		this.storeCommand(command);
	}

	async test(query)  //For testing PretendDB SQL queries in browser TODO disable in production
	{
		await this.getDb().each(query,[],row=>{console.log(row)});
	}
}

export default ConnectionToServer;


function checkAllFieldInputs(isCreate,tableName,fields)
{
    /* Check field inputs: */
    const table = DbTables.get(tableName);
    const tabFields = table.fields();
    var row = {};
    for (var [name,fieldType] of tabFields)
    {
        row[name] = fields[name];
        if (DbId.am(fields[name]))
            continue;
        /* Note that Form.js validates these fields first.  May be able to do without this extra check. */
        fieldType.validate(fields[name]);
    }

    if (isCreate)
	{
        const fieldName = table.getCreationAncestor().idName();
		if (fieldName in fields)
	        row[fieldName] = fields[fieldName];
	}
	else
        row.id = fields.id;

    /* Checks the number of fields is the same.  Note tables.fields() doesn't include 'id'. */
    var size = isCreate ? tabFields.size + 1 : tabFields.size;
    if (fields.length == size)
        throw Error('Extra fields found');

    return row;
}

