import PageParent from './PageParent';
import Form from './Form';
import EventStageTable from './EventStageTable';

class EventStagePage extends PageParent   //XXX consider creating "FormPage" (child of PageParent that contains a single Form)
{
	constructor()
	{
		super('EventStage','eventStagePage.njk');
		this.form = new Form('form','#form',['EventStage'],[]); 
		this.addComponent(this.form);
	}

    changePage(context,next,mode)
    {
console.log("EventStagePage changePage()");
		this.eventId = Number(context.params.pid);
		this.form.changeIndex(mode,Number(context.params.id),[this.eventId]);
    }

    submit(e)
    {
        e.preventDefault();
		this.setLeavingPage();
        this.form.save().then(() => toPage('/event/'+this.eventId))
.catch(e => console.log('GOT ERRORS:',e));  //XXX standardise these errors?
    }

    delete(e)
    {
        e.preventDefault();
        this.form.delete().then(() => toPage('/event/'+this.eventId));
    }
}

export default EventStagePage;
