/*
	A component is generally a block of related data that appears on a page.
	Currently display issues (i.e. updating the DOM) is handled by the *Page classes, not
	by the Component classes.
	
	An alternative approach would be to have components handle their own display.  This is
	not done as the display is handled by Nunjucks in its own layer.  It is, however, 
	possible to mirror components at the Nunjucks level.  
	Some of the pros of using the Nunjucks layering approach are:
		1. Easier to reskin
		2. Less technical staff can modify the GUI
		3. Display is kept well away from JS code, including framework code.

	A page will usually call the following functions in the following order each time
	a page changes:
		changeIndex()
		load()
		display()

	Caching of state
	----------------
	Any data that is to be cached between page views should be added as a data member 
	to child classes. 

	By default each Component class just caches the last viewed data. Component 
	objects should NOT be destroyed when pages change - leaving them around allows the last set
	of data to be cached and quickly redisplayed. 

	When a page is redisplayed a check needs to be made to see that the key ID is the same
	as the last view.  If the keys match the old data can be shown. Any updates that have come in from
	the DB in the interim should have been merged in.

	Each page is registered with the DB interface when it is created and it is up to the page to pass
	updates to components.  
	NOTE: we should consider making the components directly responsible for getting these updates.
          If we do this, occasionally they may need to call the parent's display() function.
 */
class Component
{
	constructor(name)
	{
		this.instanceName = name;
		this.needsLoad = true;
		this.setNeedsLoad = this.setNeedsLoad.bind(this);
		this.handleDbUpdate = this.handleDbUpdate.bind(this);
	}

	/* Loads state. Returns a promise. */
	load()
	{
		if (!this.needsLoad)
			return Promise.resolve();

		this.needsLoad = false;
		return this.load();
	}

	/* 
        Returns the data in a form suitable for rendering the template.
        It is NOT the job of this function to modify the object state.
    */
	getDisplayData()
	{
	}

	/*
		Copies any data sitting in input fields into the page data (browser only, not server).
		Useful when a form has a single save button, but also contains dynamic fields that are modified
		in response to other fields - i.e. occasional re-rendering is required.
	 */
	copyInputsToData()
	{
	}

	/* 
		Stores the page ID. This is called before "load()".
		Child implementations can add extra params.
		this.needsLoad must be set to true if the index has changed.
	*/
    changeIndex(mode,id) 
    {
    }

	/* Return true if the component's data has been modified */
	handleDbUpdate(table,operation,id,data)
	{
	}

	/* Call this if, e.g. the page ID has changed.  */
	setNeedsLoad()
	{
		this.needsLoad = true;
	}
}

export default Component;
