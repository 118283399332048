import PageParent from './PageParent';
import Form from './Form';
import ArtistTable from './ArtistTable';

class ArtistPage extends PageParent
{
//TODO if I delete an artist and the artist profile page is shown in another tab?

	constructor()
	{
		super('Artist','artistPage.njk');
		this.form = new Form('form','#form',['Artist'],[]);
		this.addComponent(this.form);
        this.imageUploaderPromise = null;
		this.doInit3rdPartyComponents = true;
	}

    changePage(context,next,mode)
    {
        const id = Number(context.params.id);
		this.form.changeIndex(mode,id,[null]);
    }

    getDisplayData()
    {
        const data = super.getDisplayData();
        const rand = Math.floor(Math.random()*1000000000);

        return {...data, cacheb:rand };
    }

    display()
    {
		if (this.isFirstDisplay)
			this.doInit3rdPartyComponents = true;

		if ((this.form.mode()=='edit' || this.form.mode()=='create') && this.doInit3rdPartyComponents)
		{
			this.doInit3rdPartyComponents = false;

			this.imageUploaderPromise = import('./imageUploader')
				.then(uploader => uploader.initImageUploader('Artist.pressShot'));

			const artist = this.form.entry.Artist;
			if (this.form.mode()=='edit' && artist.pressShot)
				this.imageUploaderPromise.then(() => {
						window.showExistingImage(window.imagesUrl+'/small/'+artist.id+'.jpg'
							+'?'+Math.floor(Math.random()*1000000000));
					});
		}

        super.display();
    }

    displayDiffOptions()
    {
		const pressShot = document.getElementById('value__Artist.pressShot');
        return { onBeforeElUpdated:(fromEl, toEl) => fromEl!=pressShot };
    }

    submit(e)
    {
        e.preventDefault();
        this.form.save()
            .then(() => toPage('/artist/'+this.form.id(),null))
            .catch(err => {
                    this.display();
                });
    }

    delete(e)
    {
        e.preventDefault();
//FIXME return to caller.  Might page.js store the caller somewhere?  It's not in context

		if (confirm('Are you sure you wish to delete this artist?'))
        	this.form.delete(e).then(() => toPage('/',null) );
    }

    disableSubmit()
    {
        /* Calling display() instead too easily creates endless loops */
        document.querySelector("button[type='submit']").disabled = true;
    }

    enableSubmit()
    {
        document.querySelector("button[type='submit']").disabled = false;
    }

	cancel()
	{
		/* 
			Cancel caching as music embed provider and possibly artist image have
			browser-specific state.
		 */
		this.form.entry = null;
		if (this.form.id() != null)
			toPage('/artist/'+this.form.id());
		else
			toPage('/artists');
	}
}

export default ArtistPage;
