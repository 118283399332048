import DbTable from './DbTable';
import DbTables from './DbTables';
import Type from './DbFieldTypes';
import DbSelect from './DbSelect';

//XXX PROBABLY RENAME: stage => room, place, location  ... more general, working with a wide range of events. A venue has multiple rooms.  Maybe for "venues" call them rooms and for events call them stages.  BUT stage works well with events, and it does give a notion of a place where something is happening...
class VenueStageTable extends DbTable
{
    constructor(db)
    {
        super('VenueStage',db);
        this.myFields = new Map([
                ['name',     Type.string() ],  /* Optional? */
                ['location', Type.string().optional() ],  /* Optional? */
				// desciption
				// technical specs...
            ]);

        this.myServerOnlyFields = new Map([
                ['venueId',  Type.id() ],
			]);
    }

    testData()
    {
        return  [
			{id:0, venueId:1, name:'Waywards', location:null},
			{id:1, venueId:1, name:'Front bar', location:null},
			{id:2, venueId:null, name:'Dummy name or null?', location:null},
			{id:3, venueId:1, name:'Courtyard', location:null},
        ];
    }

	ownerTable()
	{
		return DbTables.get('Venue');
	}

//XXX is this actually being used?
	async list(venueId)
	{
		const select = new DbSelect(['id','venueId','name','location'],'VenueStage').where('venueId','=');
		return await this.db.table(select,[venueId]);
	}
}

export default VenueStageTable;
