import DbTable from './DbTable';
import DbInsert from './DbInsert';
import DbSelect from './DbSelect';
import DbShallowDelete from './DbShallowDelete';
import Type from './DbFieldTypes';

class AbuseTable extends DbTable 
{
    constructor(db)
    {
        super('Abuse',db);
        this.myFields = new Map([
                ['type',        Type.string() ],
                ['email',       Type.email() ],
                ['key',         Type.string().optional() ],
                ['reporterIp',  Type.string() ],
                ['date',        Type.dateTime() ],
            ]);
//FIXME add index on date
//FIXME limit size of 'email' and 'key' to revent abuse

		this.myServerOnlyFields = new Map([
			]);
    }

    async addReport(abuseType,emailStr,keyStr,reporterIp)
	{
		const type = Type.abuseType().getValidated(abuseType,null);
		const email = Type.email().getValidated(emailStr,null);
		const key = Type.key().getValidated(keyStr,null);

//TODO Should throttle these messages or BETTER:  add a unique key to each "report abuse" link and only allow it to be pressed once.

		/* Limiting the size of this DB table: */
		const select = new DbSelect([],'Abuse').fieldUnsafe('COUNT(id)');
		const size = await this.db.cell(select,[]);
		const maxSize = 50000; 
		if (size>maxSize)
		{
			//XXX this can be done in one MySql instruction
			const select2 = new DbSelect(['id'],'Abuse').orderBy(['Abuse.date']).limit(size - maxSize);
			await this.db.each(select2, [], async item => {
					const id = item[Object.keys(item)[0]];
					const del = new DbShallowDelete('Abuse').where('id','=');
					await this.db.run(del,[id]);
				} );
		}

		const insert = new DbInsert('Abuse')
			.setToValue('type').setToValue('email')
			.setToValue('key').setToValue('reporterIp')
			.setToValue('date');
		await this.db.run(insert,[
			type,
			Type.email().jsToSql(email),
			Type.email().jsToSql(key),
			reporterIp,(new Date()).toISOString()]);
	}

	amPermissionRoot()
	{
		return true;
	}

    async havePermission(user,opType,id,column)
    {
		return user.amAdmin();
    }
}

export default AbuseTable;

