import DbTable from './DbTable';
import Type from './DbFieldTypes';
import DbTables from './DbTables';

class DayAlertTable extends DbTable
{
	constructor(db)
	{
		super('DayAlert',db);
		this.myFields = new Map([
				['day',Type.positiveIntegerOrZero()]  //TODO remove the 0 option :-)
			]);
        this.myServerOnlyFields = new Map([
                ['managerId', Type.id() ]
            ]);
	}

	testData()
	{
		return  [
			{id:0, managerId:0, day:8},
			{id:1, managerId:0, day:20}
		];
	}

    ownerTable()
    {
        return DbTables.get('Manager');
    }
}

export default DayAlertTable; 
