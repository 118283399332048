import DbSelect from './DbSelect';

export async function havePermissionImpl(user,table,opType,id,column,ownerId)
{
	if (opType!='CREATE_ROW')
	{
		const query = table.ownedRowsQuery(user)
						.field(table.name()+'.id').where(table.name()+'.id','=');

		return await table.db.cell(query,[id])!=null; 
	}

	/* Handle CREATE_ROW: */
	if (table.amPermissionRoot()) 
		/* Top-most tables require their own implementation */
		return false;  //XXX or maybe allow if admin

	const owner = table.hasMultiOwnerTable() ? 
			table.ownerTable().ownerTable() : table.ownerTable();

	
	/* CREATE_ROW: */
	const oid = owner.name()+'.id';
	const query = owner.ownedRowsQuery(user).field(oid).where(oid,'=');
	return await table.db.cell(query,[ownerId])!=null;
}

export function ownedRowsQueryImpl(user,table)
{
	if (table.amPermissionRoot()) 
	{
		const id = table.idName()=='personId' ? user.personId() : user.managerId();
		return new DbSelect([],table.name()).where(table.name()+'.id','=').resolve([id]);
	}

	const owner = table.ownerTable();
	const head = owner.ownedRowsQuery(user);

	if (table.hasMultiOwnerTable())
		return head.innerJoin(owner.name()+'.'+table.idName(),table.name()+'.id');
	else
		return head.innerJoin(owner.name()+'.id',table.name()+'.'+owner.idName());
}
