import DbTable from './DbTable';
import Type from './DbFieldTypes';
import VenueManagerTable from './VenueManagerTable';

class VenueTable extends DbTable
{
    constructor(db)
    {
        super('Venue',db);
		this.ownersTable = new VenueManagerTable(db);

        this.myFields = new Map([
                ['name',  Type.string() ],
						//TODO look for uniqueness of email and/or physical address
//                ['email',  Type.email() ],  //TODO. Should we link to a User?
//                ['address',  Type.string() ], //FIXME
// desciption
// technical specs...
 //               ['facebookPage',  Type.facebookPage() ],
  //              ['instagramHandle',  Type.instagramHandle() ],
            ]);
    }

    testData()
    {
        return  [
			{id:0, name:'The Town Hall Hotel ("The Townie")'  },
			{id:1, name:'The Newtown Hotel ("The Newtown")'  },
			{id:2, name:'The Union ("The Union")' },
			{id:3, name:'The Newtown Social Club ("The Sando")' }
        ];
    }

    hasMultiOwnerTable()
    {
        return true;
    }

	ownerTable()
	{
		return this.ownersTable;
	}

//TODO rename query to listOwned(),   list(),  ownerList(), ownerListQuery() here and elsewhere.  
//     This query is possibly standard enough to make a standard DB operation
//   NOTE there will often be 2 types of list:  listAvailableToUser()  and listAvailableToAdmin()
//         ( cf adminListQuery() or adminList() )
//XXX is this actually being used?
    async query(user)
    {
		const query = this.ownedRowsQuery(user).fields(['id','name']);
		const rows = await this.db.table(query,[]);
        const ret = [];
		for (row of rows)
			ret.push(row);
		return ret;
    }
}

export default VenueTable;
