import PageParent from './PageParent';
import {pageUpdate} from './toPage';
import List from './List';
import DbQueries from './DbQueries';

class UserListPage extends PageParent
{
    constructor()
    {
        super('UserList','userListPage.njk');
        this.list = new List('users',DbQueries.get('UserList'));
        this.addComponent(this.list);

        this.users = [];
    }
}

export default UserListPage;


