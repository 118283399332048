import {safeComparison,DbOperation} from './DbOperation';

class DbInsert extends DbOperation
{
    constructor(table)
    {
        super();

        this.table = table;
        this.setToValues = [];
    }

    type()
    {
        return 'insert';
    }

    setToValue(lhs)
    {
        this.setToValues.push(lhs);
        return this;
    }
}

export default DbInsert;
