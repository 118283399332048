import Query from './Query';
import DbTables from './DbTables';

class ArtistListQuery extends Query
{
    constructor(db)
    {
        super('ArtistList',db);
    }

	async run(user)
	{
		const table = DbTables.get('Artist');
		const query = table.ownedRowsQuery(user).fields(['Artist.id','name','tagline']);
        const ret = [];
		for (let i of await this.db.table(query,[]))
			ret.push(i);
		return ret;
	}

	structure()
	{
		return {Artist:{}};
	}
}

export default ArtistListQuery; 
