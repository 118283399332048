import {havePermissionImpl,ownedRowsQueryImpl} from './TableImpl'; 

/* This is an abstract parent class for all DB tables.  */

class DbTable
{
//FIXME ensure that DB operations are not deployed to browsers.  They need to stripped out SOMEHOW...

	constructor(name,db) 
	{
		this.myName = name;
		this.db = db;
		this.myServerOnlyFields = new Map();
        this.indexes = [];

		/* These are back links from ownerTable() and are initialised globally: */
		this.myOwnedTables = [];
	}

	name()
	{
		return this.myName;
	}

	/* 
		Other tables that link to this table should use this column name.  The default implementation 
		is a bit frameworky, but child classes are free to override it if necessary.
	*/
	idName() 
	{
		const name = this.name();
		return name.charAt(0).toLowerCase() + name.slice(1) + 'Id';
	}

	testData() 
	{
		return [];
	}

	/*
		Shouldn't need overriding.  Create "myFields" in the constructor. 
		Probably should just include no-nonsense fields that the owner can view and update
		without restrictions and transformations.  Still thinking about this though.
		Note: adjustReadRowQuery() and adjustReadRowResults() is one way to deal with trickier cases.
	 */
	fields() 
	{
		return this.myFields;
	}

	/* Fields that should only be available on the server: */
	serverOnlyFields() 
	{
		return this.myServerOnlyFields;
	}

	/* 
		Use this when this when our table has a parent table that acts as an "owner".
		Generally we trace up this ownership hierarchy to determine whether a user has 
		permission to view or modify fields in our table.  The relationship can probably 
		be mapped to the compositional class relationship in UML.

		In some cases a row in our table will be owned by multiple rows in its parent owner.  
		In this case override hasMultiOwnerTable() to return true.  In this case ownerTable() 
		should return a many-to-many table. The many-to-many table is best
		thought as being in the middle of the "ownership hierarchy", e.g.     (set tab=4 spaces) 

  			   Manager1       Manager2       ^
			      ^               ^          |
                  |               |          |
			ArtistManager1  ArtistManager2   | ownership	
			      |               |          |
				  +-------+-------+          |
                          |                  |
                          V                  |
                       Artist1               |

		Note that ArtistManager has references to Artist and Manager so in a regular table diagram
		Artist and Manager might be peers with ArtistManager as a child of both. The above diagram
		presents a clearer picture of ownership.
	*/
	ownerTable()
    {
		return null;
    }

	/* See the notes for ownerTable() */
	hasMultiOwnerTable()
	{
		return false;
	}

	/* Is this table the top of the ownership hierarchy?  */
	amPermissionRoot()
	{
		return false;
	}

	/* 
		These are back links from ownerTable() and are initialised globally.
		The function shouldn't be overridden.
	 */
	ownedTables()
	{
		return this.myOwnedTables;
	}

	/* 
		Returns the ancestor table required when creating a row.  For singly-owner tables this 
		is the parent table. For multiple owners it is the grandparent.
	 */
	getCreationAncestor()
	{
        return this.hasMultiOwnerTable() ?  this.ownerTable().ownerTable() : this.ownerTable();
	}

	/* 
		Returns the ancestor table row ID required when creating a new row.  If the top of the
		ownership tree is reached a user ID is used.
	 */
	getCreationAncestorId(user,row)
	{
		const ancestor = this.getCreationAncestor(row);
        if (ancestor.amPermissionRoot())
			return ancestor.idName()=='personId' ? user.personId() : user.managerId();

        return row[ancestor.idName()];
	}

    async havePermission(user,opType,id,column,ownerId)
	{
		return await havePermissionImpl(user,this,opType,id,column,ownerId);
	}

	/* Creates a query stub capable of returning all the rows we own */
    ownedRowsQuery(user)  
    {
		return ownedRowsQueryImpl(user,this);
    }

	/* 
		Can be override if unusual initial values are required - otherwise take the default values
		from DbFieldType.js
	 */
    initialRow() 
	{ 
		//XXX warning: this mechanism hasn't been fully implemented yet
		return {};
	}

	/* 
		This hook allows transactions to be added or modified. 
		A LIST of browser-style operations (ie not SQL operations) needs to be returned.
	 */
	addOperationDependencies(user,op)
	{
		return [op];
	}

	/*  
		A hook that is called just before a row of this table is created by DbTransactor.
		Returns any extra fields or modified fields to be committed.
	 */
	async beforeCreate(user,row,idVariable,newRowIds)
	{
		return {};
	}

	/*  
		A hook that is called just after a row of this table is created by DbTransactor.
        Note newRowIds now contains the inserted row ID.
	 */
	async afterCreate(user,row,idVariable,newRowIds)
	{
	}

	/*  A hook that is called just before a row of this table is updated by DbTransactor.  */
	async beforeUpdate(update,user,row,id,newRowIds)
	{
        return update;
	}

	/*  A hook that is called just after a row of this table is deleted by DbTransactor.  */
	async beforeCascadeDelete(user,id)
	{
	}

	/* 
		A hook called before a read row operation is performed. Can use e.g. to temporarily
		add private DB fields.
	 */
	async adjustReadRowQuery(query) { return query; }

	/* 
		A hook called after a read row operation. Can be used to e.g. replace certain fields
		with other fields. 
		"rawRow" is the row as returned from the DB query. 
		"convRow" has the fields translated into JS.  Also any fields not in "fields()" will have been
				  stripped.
	 */
	async adjustReadRowResults(rawRow,convRow) { return convRow; }

	//XXX might want similar hooks for updates
}

export default DbTable;
