/*
    Part of a DB query construction library. The parent of all DbOperations.
 */
export class DbOperation
{
	constructor()
	{
		this.resolved = [];
	}

	type()
	{
		throw new Error('Type not provided');
	}

    /*
		Most parameters will be resolved by the DbExecutor rather than the DbOperation, but
		sometimes its handy to resolve one or two while constructing the operation itself.
     */
    resolve(params)
    {
        this.resolved = [...this.resolved,...params];
		return this;
    }
}
export default DbOperation;

/* ------- Suppot routines: ------- */
export function safeComparison(op)
{
    const o = op.toLowerCase(op);
    switch(o)
    {
        case '=':
        case '!=':
        case '>':
        case '>=':
        case '<':
        case '<=':
        case 'in':
        case 'like':
            return true;
    }
    return false;
}
