import DbTable from './DbTable';
import DbTables from './DbTables';
import Type from './DbFieldTypes';

class ArtistManagerTable extends DbTable
{
    constructor(db)
    {
        super('ArtistManager',db);
        this.myFields = new Map([
            ]);

        this.myServerOnlyFields = new Map([
                ["managerId", Type.id()],
                ["artistId",  Type.id()]
			]);
    }

    ownerTable()
    {
        return DbTables.get('Manager');
    }

    testData()
    {
        return  [
            {id:0, managerId:1, artistId:1 },
            {id:0, managerId:1, artistId:2 },
            {id:0, managerId:1, artistId:3 },
        ];
    }
}

export default ArtistManagerTable;
