import {safeComparison,DbOperation} from './DbOperation';

/* This is a CASCADE delete. Ie "owned" children should be deleted too. */
class DbCascadeDelete extends DbOperation
{
    constructor(table)
    {
        super();

        this.tableFieldRegex = /^(\S+)\s*\.\s*(\S+)$/;
        this.table = table;
		this.whereFields = [];
    }

    type()
    {
        return 'cascadeDelete';
    }

    /* 
        Add a where clause with the left hand side being a field name. 
        The operations will be checked for safety.

        The values all need to passed in at the command execution stage.  They are unnamed and
        need to be added in the same order as they appear in the WHERE clauses.
     */
    where(field,op)
    {
//XXX alt with cascade delete start with a SELECT

		/* For the moment support for cascade delete is very limited as Sqlite does not provide it.  */
		if (field!='id' || op!='=')
			throw new Error('DbCascadeDelete does not support this where clause');

/*
        if (!safeComparison(op))
            throw new Error("Unsafe comparison");
*/

        this.whereFields.push({field:field,op:op});
        return this;
    }
}

export default DbCascadeDelete;
