/* This class just store user information for use in one request.  */
class User 
{
    constructor(email,personId,managerId,amAdmin)
    {
        this.myEmail = email;
        this.myPersonId = personId;
        this.myManagerId = managerId;
        this.myAdmin = amAdmin;
console.log('AM ADMIN  amAdmin:',this.myAdmin);
    }

	personId()
	{
		return this.myPersonId;
	}

	managerId()
	{
		return this.myManagerId;
	}

	email()
	{
		return this.myEmail;
	}

    amAdmin()
    {
        return this.myAdmin;
    }
}

export default User;

