import PageParent from './PageParent';
import Form from './Form';
import SetTable from './SetTable';

class SetPage extends PageParent
{
    constructor()
    {
        super('Set','setPage.njk');

		const tables = ['Set','EventArtist'];
		const tableJoins = [{table:'Set',id:'eventArtistId'}];

        this.form = new Form('form','#form',tables,tableJoins);
		this.addComponent(this.form);
	}

    changePage(context,next,mode)
    {
		this.eventId = Number(context.params.eid);
		const ownerId = Number(context.params.pid);
        this.form.changeIndex(mode,Number(context.params.id),[ownerId,this.eventId]);
    }

	submit(e)
	{
		e.preventDefault();
		this.form.save().then(() => toPage('/event/'+this.eventId));
	}

	delete(e)
	{
		e.preventDefault();
		this.form.delete().then(() => toPage('/event/'+this.eventId));
	}
}

export default SetPage;
