import Component from './Component';
import DbTables from './DbTables';
import DbId from './DbId';
import {GenreField,MusicEmbedProviderField} from './DbFieldTypes';

/*
	A CRUD form component that can be added to pages.
	Note that the form must be created in a page constructor for caching to work.  
	Only the last entry is cached.
 */
class Form extends Component
{
	/* 
		"tableList": lists the tables on the form.  
		The tables must be listed in "base-first ordering" - ie the base table is first and the
		top-most ancestors last. 
		NOTE: 	"base-first" is good for updating the UI
				"base-last" is good for inserting into the database.
	 */
	constructor(instanceName,formSelector,tableList,joins)
	{
		super(instanceName);
		this.tables = null;
		this.joins = null;
		this.formSelector = formSelector;
		this.myMode = null;  		// What about a form with multiple modes? 
		this.baseTable = null;
		this.entry = null;
        this.errors = {};
        /* newId is just used between changePage() and load(). After that this.entry.id is used */
        this.newId = null;

		/*
			Initial values used in the form during creation operations. They override those in the *Table classes.
			The table IDs of the owners of the top-level tables will generally need to be included here.
		 */
		this.initialValues = null;

		this.setTableStructure(tableList,joins);
	}

	/*
		Child classes need to be able to decide dynamically whether to need to create parent classes,
		link to existing ones, etc.  Thus it is necessary to make dynamic adjustments to the table structure
		which is why this function is provided.
	 */
	setTableStructure(tableList,joins)
	{
		this.tables = [];
		for (const t of tableList)
			this.tables.push(DbTables.get(t));

		this.joins = joins;
		this.baseTable = this.tables[0];
	}

    getDisplayData(env)
    {
        const data = {};

        for (let table of this.tables) 
        {
            const viewData = {};
			const id = this.id();
			if (id!=null)
				viewData.id = id;
            data[table.name()] = viewData;
            for (let [key,fieldType] of table.fields())
                viewData[key] = fieldType.viewData(this.entry[table.name()][key],env);
        }

        const ret = {
                entry: data,
                mode: this.myMode
            };
        ret.errors = this.errors;
        return ret;
    }

	id()
	{
		if (this.entry==null)
			return null;

		const e = this.entry[this.baseTable.name()];
		if (typeof e!='undefined' && 'id' in e)
			return e.id;

		return null;
	}

	mode()
	{
		return this.myMode;
	}

	/* ownerIds - an array in the same order as this.tables. Use a "null" for top-most tables.  */
    changeIndex(mode,baseTableId,ownerIds)
    {
		if (mode!='create' && mode!='view' && mode!='edit')
			throw Error("Missing or invalid mode");

        if ((mode=='edit'||mode=='view') && baseTableId!=this.id())
        {
            this.needsLoad = true;
            this.newId = baseTableId;
        }

        this.myMode = mode;

		if (mode=='create')
		{
			this.initialValues = new Map();
			let i = 0;
			for (const tab of this.tables)
			{
				if (ownerIds[i]!=null)
				{
					const obj = {};
					obj[tab.ownerTable().idName()] = ownerIds[i];
					this.initialValues.set(tab.name(),obj);
				}
				i++;
			}
		}
    }

	copyInputsToData()
	{
		const ret = this.getFormData(document.querySelector(this.formSelector));

		this.entry = {};
		for (const t of ret[0])
			this.entry[t.table] = t.fields;
	}

    save()
    {
        let formFields;
        [formFields,this.errors] = this.getFormData(document.querySelector(this.formSelector));
        if (Object.keys(this.errors).length > 0)
            return Promise.reject(this.errors);

        if (this.myMode=="edit")
        {
            //XXX if I implement an optimistic version then I need to set this.entry here...
            db.startTransaction();
			for (const tab of formFields)
            	db.updateRow(tab.table,tab.fields);

            /* 
				NOTE: an optimistic update could be an option for some forms, but it doesnt work 
				well when replacing images, or if the server needs to return errors.
			 */
            return db.finishTransaction().then((updates,variables) => { 
                    for (let update of updates)
                        this.entry[update.table] = Object.assign({},update.row);
                })
				.catch(err => {
					this.errors = err.fieldErrors;
					throw e;
				});
        }

        if (this.myMode=="create")
        {
            //XXX if I implement an optimistic version then I need to set this.entry here...
            db.startTransaction();

			const tabMap = new Map();
			for (const t of formFields)
				tabMap.set(t.table,t.fields);

			/* Note transactions are in "base-first" order */
			for (const t of this.tables)
			{
				const row = tabMap.get(t.name());

				/* Add the internal table joins as fields: */
				for (let join of this.joins)
					if (join.table==t.name())
					{
						const joinTable = DbTables.get(join.table).associations().get(join.id);
						row[join.id] = new DbId(joinTable+'.id');
					}

            	db.createRow(t.name(),row,new DbId(t.name()+".id"));
			}

            /* Have to wait for an ID before continuing... */
            return db.finishTransaction().then((updates,variables) => {
                    for (let update of updates)
                    {
                        this.entry[update.table] = Object.assign({},update.row);
                        this.entry[update.table].id = update.id;
                    }
                })
				.catch(err => {
					this.errors = err.fieldErrors;
					throw e;
				});

        }
        throw new Error("Invalid mode: "+myMode);
    }

    delete(event)
    {
//XXX currently just deleting the base table.  May need to allow child classes to delete
//    parent classes too in some cases
        db.startTransaction();
        db.deleteRow(this.baseTable.name(),this.id());
        db.finishTransactionNoReturnValues();
        /* Using an optimistic update so resolve immediately: */
        return Promise.resolve();
    }

	getFormData(formNode)
	{
		var tableFields = [];
        const errors = {};

        for (let table of this.tables) 
        {
            const fields = {};

            for (let [key,fieldType] of table.fields())
            {
                let formValue = null;
                formValue = document.getElementById(table.name()+'.'+key).value;

                fields[key] = fieldType.fromUserString(formValue);
                const error = fieldType.validate(fields[key]);
                if (error!=null)
                    errors[table.name()+'.'+key] = error;
            }

            if (this.myMode=='edit')
                fields['id'] = Number(formNode.querySelector("input[name='"+table.name()+".id']").value);
            else if (this.myMode=="create")
            {
                const ancestor = table.getCreationAncestor();
                const fieldName = table.name()+'.'+ancestor.idName();
                const node = formNode.querySelector("input[name='"+fieldName+"']");
                const formValue = node==null ? null : Number(null);

                if (formValue!=null && !Number.isNaN(formValue))
                    fields[ancestor.idName()] = formValue;
            }

            tableFields.push({table:table.name(),fields:fields});
        }

        return [tableFields,errors];
	}

	load()
	{
        var rowPromise;
        if (this.myMode=='create')
		{
			let combined = {};
			for (const tab of this.tables)
			{
				let row = Object.assign(tab.initialRow(),this.initialValues.get(tab.name()));
				combined[tab.name()] = row;
			}

		    this.entry = combined;
            return Promise.resolve();
		}
        else if (this.newId!=null)
			return db.readRow(this.baseTable.name(),this.newId,this.joins).then(data => {
					this.entry = data;
					this.newId = null;
				});
		else
			/* Use cached row: */ 
			return Promise.resolve();
	}

    handleDbUpdate(table,operation,id,data)
    {
        if (operation!='UPDATE_ROW' || (this.myMode!='view' && this.myMode!='edit'))
            return false;

		if (table in this.entry && id==this.entry[table].id)
		{
console.log('In Form.handleDbUpdate() operation:',operation,'myMode:',this.myMode,'table:',table,'data:',data);
			this.entry[table] = data;
			this.entry[table].id = id;
			// Consider removing parent IDs
			/* If in edit view we update this.entry but don't refresh - don't want to overwrite the user's work */
			return this.myMode=='view';
		}

        return false;
    }
}

export default Form;
