import PageParent from './PageParent';
import List from './List';
import DbQueries from './DbQueries';

class CompanyListPage extends PageParent
{
    constructor()
    {
        super('CompanyList','companyListPage.njk');
		this.list = new List('companies',DbQueries.get('CompanyList'));
		this.addComponent(this.list);
    }

	delete(id)
	{
        db.startTransaction();
        db.deleteRow('Company',id);
        db.finishTransactionNoReturnValues();
	}
}

export default CompanyListPage;
