import CalendarQuery from './CalendarQuery';
import ArtistListQuery from './ArtistListQuery';
import UserListQuery from './UserListQuery';
import EventLineupsQuery from './EventLineupsQuery';
import EventsOnDayQuery from './EventsOnDayQuery';
import CompanyListQuery from './CompanyListQuery';

class DbQueries
{
	static init(db)
	{
		map = new Map();
		addToMap(new EventLineupsQuery(db));
		addToMap(new CalendarQuery(db));
		addToMap(new EventsOnDayQuery(db));
		addToMap(new ArtistListQuery(db));
		addToMap(new UserListQuery(db));
		addToMap(new CompanyListQuery(db));
	}

    static all()
    {
        return map;
    }

    static get(name)
    {
        return map.get(name);
    }
}

export default DbQueries;


//---- Support functions-----

var map = null;

function addToMap(item)
{
    map.set(item.name(),item);
}
