import {safeComparison,DbOperation} from './DbOperation';

/*
	Part of a DB query constuctor. Constructs a SELECT object.  Use Sqljs.make() or equivalent to
	actually construct the query.
 */
class DbSelect extends DbOperation
{
	constructor(fields,fromTable)
	{
		super();

		this.asRegex = /^(\S+)\s+(as|AS)\s+(\S+)$/;
		this.tableFieldRegex = /^(\S+)\s*\.\s*(\S+)$/;
		this.fieldList = [];
		this.fieldUnsafes = [];
		this.fromTable = fromTable;
		this.innerJoins = [];
		this.leftJoins = [];
        /* When a where field is an array it is an OR-clause of conditions */
		this.whereFields = [];
        //XXX this is currently not compatible with the regular where clause
		this.whereUnsafes = [];
		this.orderBys = [];
		this.fields(fields);
		this.myLimit = null;
	}

	type()
	{
		return 'select';
	}

	/*
		"fields" is an array of fields.  The fields can optionally contain "." and "as".
		Example usage:
			q = q.fields(['Artist.name','tagline','Stage.name as stageName']);
	 */
	fields(fields)
	{
		for (let f of fields)
			this.field(f);
		return this;
	}

	field(field) 
	{
		let match = this.asRegex.exec(field);
		let fieldTerm = match!=null ? match[1] : field;
		let asTerm =    match!=null ? match[3] : null;

		match = this.tableFieldRegex.exec(fieldTerm);
		let table  = match!=null ? match[1] : null;
		let column = match!=null ? match[2] : fieldTerm;

		this.fieldList.push({table:table,column:column,as:asTerm});
		return this;
	}

	/* Allows expressions to be added to the SELECT clause, but no checking is performed.  */
	fieldUnsafe(expression)
	{
		this.fieldUnsafes.push(expression);
		return this;
	} 

	/* 
		Adds a bunch of fields from a table and prepends a table name on to the front,
		e.g. tableFields('Lineup',['id','start'])
		'as' and dot notation are not supported here.
	 */
	tableFields(table,fields)
	{
		for (let field of fields)
			this.fieldList.push({table:table,column:field,as:table+'__'+field});
		return this;
	}

	removeFields()
	{
		throw new Error("TO IMPLEMENT");
		return this;
	}

	/* 
		Add an inner join. Example:
			q = q.innerJoin(['Lineup.stageId','Stage.id']);
	 */
	innerJoin(field,joinField)
	{
		let match = this.tableFieldRegex.exec(field);
		let match2 = this.tableFieldRegex.exec(joinField);
		if (match==null || match2==null)
			throw new Error('Invalid join syntax');
		this.innerJoins.push({table:match[1],field:match[2],joinTable:match2[1],joinField:match2[2]});
		return this;
	}

	/* 
		Add a left join. Example: 
			q = q.leftJoin(['Lineup.stageId','Stage.id']);
	 */
	leftJoin(field,joinField) 
	{
		let match = this.tableFieldRegex.exec(field);
		let match2 = this.tableFieldRegex.exec(joinField);
		if (match==null || match2==null)
			throw new Error('Invalid join syntax');
		this.leftJoins.push({table:match[1],field:match[2],joinTable:match2[1],joinField:match2[2]});
		return this;
	}

	/* 
		Add a where clause with the left hand side being a field name that can include
		an optional table name.  The operations are checked for safety.

		The values all need to passed in at the command execution stage.  They are unnamed and
		need to be added in the same order as they appear in the WHERE clauses.
	 */
	where(field,op)
	{
		if (!safeComparison(op))
			throw new Error("Unsafe comparison");

		let match = this.tableFieldRegex.exec(field);
		let table  = match!=null ? match[1] : null;
		let column = match!=null ? match[2] : field;

		this.whereFields.push({table:table,field:column,op:op});
		return this;
	}

    whereOr(conditions)
    {
        const a = [];
        for (let c of conditions)
        {
            if (!safeComparison(c[1]))
                throw new Error("Unsafe comparison");

            let match = this.tableFieldRegex.exec(c[0]);
            let table  = match!=null ? match[1] : null;
            let column = match!=null ? match[2] : c[0];
            a.push({table:table,field:column,op:c[1]});
        }
		this.whereFields.push(a);
        return this;
    }

	/* 
		Add a more general where clause.
		WARNING: the "field" LHS is NOT (currently) checked for safety
				(the field can include expressions like "strftime('%d',start)")

		The values all need to passed in at the command execution stage.  They are unnamed and
		need to be added in the same order as they appear in the WHERE clauses.

		XXX can we do anything to make this safer?
	 */
	whereUnsafe(lhs,op)
	{
		if (!safeComparison(op))
			throw new Error("Unsafe comparison");

		this.whereUnsafes.push({lhs:lhs,op:op});
		return this;
	}


	/*
		"fields" is an array.  A table name may be included using a '.' - e.g.
			q = q.orderBy(['Artist.name','address']);

		TODO extend to support DESC
	 */
	orderBy(fields)
	{
		for (let field of fields)
		{
			let match = this.tableFieldRegex.exec(field);
			let table  = match!=null ? match[1] : null;
			let column = match!=null ? match[2] : field;
			this.orderBys.push({table:table,column:column});
		}
		return this;
	}

	limit(number)
	{
		if (typeof number !='number')
			throw new Error(number+' is not an integer');
		this.myLimit = number;
		return this;
	}
}

export default DbSelect;
