import {safeComparison,DbOperation} from './DbOperation';

/* This is a shallow delete. */
class DbShallowDelete extends DbOperation
{
    constructor(table)
    {
        super();

        this.tableFieldRegex = /^(\S+)\s*\.\s*(\S+)$/;
        this.table = table;
		this.whereFields = [];
    }

    type()
    {
        return 'shallowDelete';
    }

    /* 
        Add a where clause with the left hand side being a field name. 
        The operations will be checked for safety.

        The values all need to passed in at the command execution stage.  They are unnamed and
        need to be added in the same order as they appear in the WHERE clauses.
     */
    where(field,op)
    {
        if (!safeComparison(op))
            throw new Error("Unsafe comparison");

        this.whereFields.push({field:field,op:op});
        return this;
    }
}

export default DbShallowDelete;
