import PageParent from './PageParent';
import List from './List';
import DbQueries from './DbQueries';

class DiaryPage extends PageParent
{
	constructor()
	{
		super('Diary','diaryPage.njk');
		this.dayNum = -1;
        this.list = new List('events',DbQueries.get('EventsOnDay'));
        this.addComponent(this.list);
	}

	changePage(context) 
	{
		this.list.changeIndex(null,Number(context.params.day));
	}
}

export default DiaryPage;
