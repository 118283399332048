import DbTables from './DbTables';
import DbQueries from './DbQueries';
import page from 'page';
import ConnectionToServer from './ConnectionToServer';
import TestDb from './server/TestDb';
import DbTransactor from './server/DbTransactor';
import DbReader from './server/DbReader';
//import SQL from 'sql.js';
const SQL = require('sql.js');

class ConnectionToDummyServer extends ConnectionToServer
{
	constructor()
	{
		super();

        this.tempDb = new TestDb(storeDb,retrieveDb); 

		DbTables.init(this.tempDb);
		DbQueries.init(this.tempDb);
		if (retrieveDb()===null)
		{
			this.tempDb.createTables();
			this.tempDb.loadTestData();
		}

        this.dbTransactor = new DbTransactor(this.tempDb,DbTables.all());
        this.dbReader = new DbTransactor(this.tempDb);
	}

    init() 
	{
        /* Initialise the localStorage listener. Allows other tabs to update. */
        window.addEventListener('storage', e => {
            if (e.key == 'dbChange')
                this.handleDbUpdates(false,JSON.parse(e.newValue));
        });
	} 

    /* Pretends to be communicating to server.  Returns a promise because its a slow operation. */
    slowPerformTransaction(transaction)
    {
        return new Promise((resolve, reject) => {
            async () => {
                try
                {
                    const [retValues,changes] = await this.dbTransactor.run(transaction);
                    this.propagateChanges(changes);
                    resolve([retValues,changes]);
                }
                catch(error)
                {
                    this.dbError(error);
                    reject();
                }
            }
        });
    }

    readRow(rootTable,rootId,joins)
    {
        return new Promise((resolve, reject) => {
            try
            {
                const row = this.dbReader.read(0,rootTable,rootId,joins); //FIXME dummy user
                resolve(row);
            }
            catch(e)
            {
                this.dbError(e);
				reject();
            }
        });
    }

    query(queryName,params)
    {
        return new Promise((resolve, reject) => {
            try
            {
                const ret = DbQueries.get(queryName).run(0,params); //FIXME dummy user
                resolve(ret);
            }
            catch(e)
            {
                this.dbError(e);
				reject();
            }
        });
    }

    /*
        Let other browser windows know about the change.  Note that it is left to the triggering
        window to inform the other, undisplayed pages within the same window.  This is done in Db.js 
     */
    propagateChanges(transaction)
    {   var lastEvent = localStorage.getItem('dbChange');
        var eventId = lastEvent===null ? 0 : JSON.parse(lastEvent).id;
        const wrapped = {id: eventId+1,updates:transaction};
        localStorage.setItem('dbChange', JSON.stringify(wrapped));
    }

    dbError(exception)
    {
console.log('dbError() exception:',exception);
        /* Invalidating all the pages is safest given optimistic updates and possible page changes. */
		window.db.invalidateListeningPages();

        if (exception.name == 'PermissionException')
        {
            console.log('Permissions error:',exception.message);
            page('/badPermissions');
        }
        else
        {
            console.log('DB connection error:',exception);
            alert('Database operation failed: ',exception);
        }
    }

	// For testing SQL queries in browser. TODO disable in production
    myDb() //XXX For testing PretendDB SQL queries in browser
    {
        return retrieveDb();
    }
}

export default ConnectionToDummyServer;

/* --- Used for saving and retrieving sql.js database in localStorage --- */

function storeDb(sqlDb)
{
    localStorage.setItem("sql",toBinString(sqlDb.export()));
}

function retrieveDb()
{
    const store = localStorage.getItem("sql");
    if (store==null)
        return null;
    return new SQL.Database(toBinArray(store));
}

function toBinArray (str)
{
    var l = str.length,
        arr = new Uint8Array(l);
    for (var i=0; i<l; i++) arr[i] = str.charCodeAt(i);
    return arr;
}

function toBinString (arr)
{
    const uarr = new Uint8Array(arr);
    const strings = [], chunksize = 0xffff;
    // There is a maximum stack size. We cannot call String.fromCharCode with as many arguments as we want
    for (let i=0; i*chunksize < uarr.length; i++)
        strings.push(String.fromCharCode.apply(null, uarr.subarray(i*chunksize, (i+1)*chunksize)));
    return strings.join('');
}
