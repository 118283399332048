import PageParent from './PageParent';
import Form from './Form';
import CompanyTable from './CompanyTable';

class CompanyPage extends PageParent
{
	constructor()
	{
		super('Company','companyPage.njk');
		this.form = new Form('form','#form',['Company'],[]);
		this.addComponent(this.form);
	}

    changePage(context,next,mode)
    {
        const id = Number(context.params.id);
		this.form.changeIndex(mode,id,[null]);
    }

    submit(e)
    {
        e.preventDefault();
        this.form.save().then(() => toPage('/organisation/'+this.form.id(),null));
    }

    delete(e)
    {
        e.preventDefault();
//FIXME return to caller.  Might page.js store the caller somewhere?  It's not in context
        this.form.delete().then(() => toPage('/organisations',null) );
    }
}

export default CompanyPage;
