import DbTable from './DbTable';
import Type from './DbFieldTypes';
import DbTables from './DbTables';
import DbSelect from './DbSelect';

class EventStageTable extends DbTable
{
    constructor(db)
    {
        super('EventStage',db);
        this.myFields = new Map([
                ['name',  Type.string() ]
//TODO uncomment location - given error in DbFieldType
/*                ['location', { type:Type.string().optional() }], */
				// desciption
				// technical specs...
            ]);
        this.myServerOnlyFields = new Map([
				['eventId',     Type.id() ],
				['venueStageId',Type.id().optional() ],   //XXX within venue. Just a link for updates
			]);
    }

    testData()
    {
        return  [
			{id:0, eventId:0, venueStageId:1, name:'Waywards', location:null},
			{id:1, eventId:0, venueStageId:1, name:'Front bar', location:null},
			{id:2, eventId:0, venueStageId:null, name:'Dummy name or null?', location:null},
			{id:3, eventId:1, venueStageId:1, name:'Courtyard', location:null},
        ];
    }

	ownerTable()
	{
		return DbTables.get('Event');
	}

//XXX is this actually being used?
	async list(eventId)
	{
		const select = new DbSelect(['VenueStage.id','eventId','venueStageId','VenueStage.name'],'VenueStage')
			.innerJoin('EventStage.venueStageId','VenueStage.id')
			.where('EventStage.eventId','=');

		return await this.db.table(select,[eventId]);
	}
}

export default EventStageTable;
