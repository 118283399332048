import DbTable from './DbTable';
import DbTables from './DbTables';
import Type from './DbFieldTypes';

class LineupTable extends DbTable
{
	constructor(db)
	{
		super('Lineup',db);
		this.myFields = new Map([
//XXX should ensure these times lie within the event times.  Similar issue with Set times too.
        		['start', Type.dateTime().optional() ],
                ['end',   Type.dateTime().optional() ],
			]);
        this.myServerOnlyFields = new Map([
				['eventStageId', Type.id() ],
			]);
	}

	ownerTable()
	{
		return DbTables.get('EventStage');
	}

	testData()
	{
		return  [
			{id:0, eventStageId:0, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
			{id:1, eventStageId:0, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
			{id:2, eventStageId:1, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
		];
	}
}

export default LineupTable; 
