import DbTable from './DbTable';
import Type from './DbFieldTypes';
import DbTables from './DbTables';

/*
	Note this table is NOT a many-to-many link table between the Event and Artist table.
	The table is basically a copy of the Artist table that is controlled by the event manager,
	i.e. there is a many-to-one relationship between this table and the Event table.
 */
class EventArtistTable extends DbTable
{
    constructor(db)
    {
        super('EventArtist',db);
        this.myFields = new Map([
				/* These fields are copies of the Artist table fields and need to be maintained: */
                ['name',    Type.string() ],
                ['tagline', Type.string() ],  //XXX length?
            ]);

        this.myServerOnlyFields = new Map([
                ['eventId',  Type.id() ],
                ['artistId', Type.id().optional() ], 
			]);
    }

    testData()
    {
        return  [
            {id:0, eventId:0, artistId:0,name:'John Jeffries', tagline:'We are great' },
            {id:1, eventId:0, artistId:1,name:'Jeff Johnries', tagline:'We are OK' },
            {id:2, eventId:0, artistId:2,name:'Jacaline Johnjackries', tagline:'We are from Newcastle' }
        ];
    }

	ownerTable()
	{
		return DbTables.get('Event');
	}
}

export default EventArtistTable;
