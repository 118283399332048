import DbTable from './DbTable';
import Type from './DbFieldTypes';
import ArtistManagerTable from './ArtistManagerTable';
import CloudFileSystemFactory from './server/CloudFileSystemFactory';
import DbSelect from './DbSelect';
import FieldTypeException from './FieldTypeException';

class ArtistTable extends DbTable
{
    constructor(db)
    {
        super('Artist',db);
		this.ownersTable = new ArtistManagerTable(db);

//TODO remove undesireable optionals	   
        this.myFields = new Map([
                ['name',        Type.string()],
                ['tagline',     Type.string(100)],

                ['country',     Type.string(100)],
                ['state',     	Type.string(10)],
                ['city',      Type.string(200)],

                ['mainGenre',   Type.genre()], 
                ['otherGenre1', Type.genre().optional()],
                ['otherGenre2', Type.genre().optional()], 
                ['extraGenre',  Type.string(32).optional()],
                ['biography',   Type.string(500)],

                /* The bandcamp and soundcloud fields are either/or */
                ['embedMusicProvider', Type.musicEmbedProvider().optional()],
                ['bandcamp',    Type.bandcamp().optional()],
                ['soundcloud',  Type.soundcloud().optional()],

                /* The YouTube, Vimeo and FacebookVideo fields are either/or */
                ['embedVideoProvider', Type.videoEmbedProvider().optional()],
                ['youtube',     Type.youtube().optional()],
                ['vimeo',       Type.vimeo().optional()],
                ['facebookVideo',Type.facebookVideo().optional()],

                ['spotify',     Type.spotify().optional()],
                ['apple',       Type.apple().optional()],

                ['website',     Type.url().optional()],
                ['facebook',    Type.facebook().optional()],
                ['instagram',   Type.instagram().optional()], 
                ['twitter',     Type.twitter().optional()], 

                ['pressShot',   Type.upload().optional()],

				/* For Facebook feeds: */
                ['facebookVideoUrl',Type.facebookVideoUrl().optional()],

//                ['bio',               Type.string()],  //XXX length?
 //               ['facebookPage',      Type.facebookPage()],
  //              ['instagramHandle',   Type.instagramHandle()],
    //            ['liveShot',          Type.uploadLocation()],
     //           ['facebookVideo',     Type.facebookEmbedCode()],  REALLY? check use in KSC
      //          ['facebookVideoDesc', Type.string()],  //XXX length
            ]);

//TODO implement autocomplete efficiently.  
//     Maybe store prefixes from the start of words.
//     Alt just strip out minor stop words and start from there?
// NOTE: MySQL full text doesnt work on part-words
// https://dba.stackexchange.com/questions/181618/autocomplete-too-slow-possible-optimizations

        this.indexes = [
//                {name:'ArtistFullText',fields:['name'],type:'fullText'}
            ];
	}

	testData()
	{
		return  [
			{id:0,name:'John Jeffries',tagline:'We are great',mainGenre:'rock',country:'Australia',state:'NSW',city:'Sydney',
                embedVideoProvider:'youtube',youtube:'vxAPSWwKiuo',vimeo:'17972817',facebookVideo:'10153949778421332',
                embedMusicProvider:'bandcamp',bandcamp:'album=3910147500',soundcloud:'tracks/245380211',
				instagram:'budpetal',facebook:'budpetal',twitter:'budpetal',web:'https://budpetal.bandcamp.com',
                spotify:'https://open.spotify.com/artist/3CIsavaS9JL6Xv07johGHE',
                apple:'https://music.apple.com/au/album/clarinet-concerto-in-major-k-622-performed-on-basset/1452659795?i=1452660835',
				biography:'Me good',facebookVideoUrl:'https://www.facebook.com/barebonesofficial/videos/611940915985201/'
			},
			{id:1,name:'Jeff Johnries',tagline:'We are OK',mainGenre:'jazz',country:'Australia',state:'VIC',city:'Melbourne',
                embedVideoProvider:'vimeo',youtube:'vxAPSWwKiuo',vimeo:'17972817',facebookVideo:'10153949778421332',
                embedMusicProvider:'soundcloud',bandcamp:'album=3910147500',soundcloud:'users/108325512',
				instagram:'budpetal',facebook:'budpetal',twitter:'budpetal',web:'https://budpetal.bandcamp.com',
                spotify:'https://open.spotify.com/artist/3CIsavaS9JL6Xv07johGHE',
                apple:'https://music.apple.com/au/album/clarinet-concerto-in-major-k-622-performed-on-basset/1452659795?i=1452660835',
				biography:'Me good',facebookVideoUrl:'https://www.facebook.com/barebonesofficial/videos/611940915985201/'
			},
			{id:2,name:'Jacaline Johnjackries',tagline:'We are from Newcastle',mainGenre:'electronica',state:'QLD',country:'Australia',city:'Brisbane',
                embedVideoProvider:'facebookVideo',youtube:'vxAPSWwKiuo',vimeo:'17972817',facebookVideo:'10153949778421332',
				embedMusicProvider:'bandcamp',bandcamp:'album=3910147500',
				instagram:'budpetal',facebook:'budpetal',twitter:'budpetal',web:'https://budpetal.bandcamp.com',
                spotify:'https://open.spotify.com/artist/3CIsavaS9JL6Xv07johGHE',
                apple:'https://music.apple.com/au/album/clarinet-concerto-in-major-k-622-performed-on-basset/1452659795?i=1452660835',
				biography:'Me good',facebookVideoUrl:'https://www.facebook.com/barebonesofficial/videos/611940915985201/'
			}
		];
	}

    hasMultiOwnerTable()
    {
        return true;
    }

	ownerTable()
	{
		return this.ownersTable;
	}

    async beforeCascadeDelete(user,id)
    {
        const imagesBucket = CloudFileSystemFactory.get('imagesBucket');

        imagesBucket.exists('artistPressShots/full/'+id).then(() => {
                const p1 = imagesBucket.delete('artistPressShots/full/'+id);
                const p2 = imagesBucket.delete('artistPressShots/medium/'+id+'.jpg');
                const p3 = imagesBucket.delete('artistPressShots/small/'+id+'.jpg');
                return Promise.all([p1,p2,p3]);
            }).catch(e=>
                console.log('ERROR:',e));
    }

    async beforeCreate(user,row,idVariable,newRowIds)
	{
		this.checkGenresDiffer(row);
	}

    async afterCreate(user,row,idVariable,newRowIds)
    {
        await this.uploadPressShot(row.pressShot,newRowIds.get('Artist.id'),user.personId());  
    }

    async beforeUpdate(update,user,row,id,newRowIds) 
    {
		this.checkGenresDiffer(row);
        await this.uploadPressShot(row.pressShot,id,user.personId());  
        return update;
    }

	checkGenresDiffer(row)
	{
		if (row['mainGenre'] == row['otherGenre1'])
			throw new FieldTypeException('This genre has been selected already','Artist.otherGenre1');
		if (row['mainGenre'] == row['otherGenre2'])
			throw new FieldTypeException('This genre has been selected already','Artist.otherGenre2');
		if ((row['otherGenre1'] == row['otherGenre2']) && (row['otherGenre1'] != null))
			throw new FieldTypeException('This genre has been selected already','Artist.otherGenre2');
		if (row['otherGenre1']==null && row['otherGenre2']!=null)
			throw new FieldTypeException('Missing genre','Artist.otherGenre2');
	}

    async uploadPressShot(tempFile,artistId,personId)
    {
        if (tempFile=='(exists)')
            return;

        // This will perform some unnecessary S3 checks during creates...
        await this.deleteArtistPressShot(artistId);
        if (!tempFile)
            return;

        /* Important to prevent hacking: */
        if (tempFile.match(/^[0-9a-zA-Z]+$/)==null)
            throw new Error('Invalid press shot file format');   //FIXME use "SecurityError"/"UserError" or similar

        const imagesBucket = CloudFileSystemFactory.get('imagesBucket');
        const targetFile = 'artistPressShots/full/'+artistId;

        return await imagesBucket.exists(targetFile)
            .then(ex => { if (ex) throw new Error('File exists') })  //FIXME "RequestError"? Dont terminate
            .then(() => {
                    const tempPrefix = 'artistPressShots/transfer/'+personId+':'+tempFile;
                    const p1 = imagesBucket.rename(tempPrefix+'.small.jpg','artistPressShots/small/'+artistId+'.jpg');
                    const p2 = imagesBucket.rename(tempPrefix+'.medium.jpg','artistPressShots/medium/'+artistId+'.jpg');
                    const p3 = imagesBucket.rename(tempPrefix+'.full',targetFile);
                    return Promise.all([p1,p2,p3]);
                });
    }

    async deleteArtistPressShot(artistId)
    {
        const imagesBucket = CloudFileSystemFactory.get('imagesBucket');

        const p1 = imagesBucket.exists('artistPressShots/full/'+artistId).then(async exists => {
                if (exists) 
                    await imagesBucket.delete('artistPressShots/full/'+artistId) });

        const p2 = imagesBucket.exists('artistPressShots/medium/'+artistId+'.jpg').then(async exists => {
                if (exists) 
                    await imagesBucket.delete('artistPressShots/medium/'+artistId+'.jpg') });

        const p3 = imagesBucket.exists('artistPressShots/small/'+artistId+'.jpg').then(async exists => {
                if (exists) 
                    await imagesBucket.delete('artistPressShots/small/'+artistId+'.jpg') });

        return Promise.all([p1,p2,p3]);
    }

    async autoCompleteSearch(term)
    {
        //XXX cf allowing region to be add to search

        /* Example query:  
                SELECT * FROM Artist WHERE name LIKE 'jeff%' OR name LIKE '% jeff%';  
            Note that I'm matching from the start of a word.

            FIXME soon I'm going to need an index to allow quick perfix lookups.
         */

console.log('autoCompleteSearch()  term:*'+term+'*');

        /* I allow for a leading space below.  Remove it here: */
        const trimmed = term.length>0 && term.charAt(0)==' ' ? term.substr(1) : term;
        if (trimmed.length < 2)
            return [];

        let query = new DbSelect(['id','name','city','mainGenre'],'Artist').limit(10);

        if (term.charAt(0)==' ')
            /* Minor case where the user deliberately searches for a middle word: */
            query = query.where('name','LIKE').resolve(['% '+trimmed+'%']);
        else
            query = query.whereOr([['name','LIKE'],['name','LIKE']]).resolve([trimmed+'%','% '+trimmed+'%']);

        let results = await this.db.table(query,[]);

        /* 
            Sort it so that results that contain the string near the start appear first.
            Note that the DB search above does NOT include this ordering in its search.
         */
        const lower = trimmed.toLowerCase();
        results.sort(function(a,b) {
                const i = a.name.toLowerCase().indexOf(lower);
                const j = b.name.toLowerCase().indexOf(lower);
                if (i<j) return -1;
                if (i>j) return 1;
                /* Give recent additions priority: */
                if (a.id < b.id) return 1;
                if (a.id > b.id) return -1;
                return 0;
            });
        
        return results;
    }

    async apiData(id)
    {
        let query = new DbSelect(['id'],'Artist').where('id','=');

        //XXX warning: not all of these fields will necessarily be for the public in future...
        //TODO: probably want an artist profile disable option too and would need to exclude these
        for (let [name,type] of this.fields())
            query.field(name);

        const row = await this.db.row(query,[id]);

		const bucket = CloudFileSystemFactory.get('imagesBucket');
		row.pressShotSmall = row.pressShot!=1 ? null : bucket.url('artistPressShots/small/'+id+'.jpg');
		row.pressShotMedium = row.pressShot!=1 ? null : bucket.url('artistPressShots/medium/'+id+'.jpg');

        return row;
    }
}

export default ArtistTable; 
