import DbParent from './DbParent';
import SqljsBuilder from './SqljsBuilder';
import DbSelect from '../DbSelect';
import DbInsert from '../DbInsert';
import DbShallowDelete from '../DbShallowDelete';
import DbTables from '../DbTables';
//import SQL from 'sql.js';
const SQL = require('sql.js');

class TestDb extends DbParent
{
    constructor(storeCB,retrieveCB)
    {
        super();
console.log('In TestDB constructor');

        /* Used for long-term storage: */
        this.storeCB = storeCB!=null ? storeCB : db => { };
        this.retrieveCB = retrieveCB!=null ? retrieveCB : () => null;

        /* Used for short-term storage: */
        this.store = retrieveCB!=null ? retrieveCB() : null;
    }

    startTransaction() {}

    endTransaction() {}

	async cell(op,params)
	{
		const qString = SqljsBuilder.make(op);
        const command = this.store.prepare(qString);
        const ret = command.getAsObject([...op.resolved,...params]);
		command.freemem();
		command.free();
		if (Object.keys(ret).length==0)
			return null;
		return ret[Object.keys(ret)[0]];
	}

	async row(op,params)
	{
		const qString = SqljsBuilder.make(op);
        const command = this.store.prepare(qString);
        const ret = command.getAsObject([...op.resolved,...params]);
		command.freemem();
		command.free();
		if (Object.keys(ret).length==0)
			return null;
		return ret;
	}

	async table(op,params)
	{
		const qString = SqljsBuilder.make(op);
        const command = this.store.prepare(qString);
        command.bind([...op.resolved,...params]);

		const rows = [];
		for (; command.step(); )
			rows.push(command.getAsObject());

		command.freemem();
		command.free();
		return rows;
	}

	async column(op,params)
	{
		const rows = await this.table(op,params);
		const col = [];
		for (const row of rows)
			col.push(Object.values(row)[0]);
		return col;
	}

	async each(op,params,callback)
	{
		const qString = SqljsBuilder.make(op);
        const command = this.store.prepare(qString);
        command.bind([...op.resolved,...params]);

		for (; command.step(); )
			await callback(command.getAsObject());

		command.freemem();
		command.free();
	}

	async insert(insertOp,params)
	{
		const newId = this.nextId(insertOp.table);
		await this.run(insertOp,params);
		return newId;
	}

	async run(op,params)
	{
		const allParams = [...op.resolved,...params];

		if (op.type()=='cascadeDelete')
		{
			// There should only be one parameter for cascade delete. Still, allParams[0] is hacky 
			await this.cascadeDelete(DbTables.get(op.table),allParams[0]);
			return;
		}

		let qString = SqljsBuilder.make(op);
        const command = this.store.prepare(qString);
        const ret = command.getAsObject(allParams);
		command.freemem();
		command.free();
        this.storeCB(this.store);
		return ret;
	}

	async nextId(table)
	{
		const select = new DbSelect([],table).fieldUnsafe('MAX(id)');
		const lastId = await this.cell(select,[]);
		return lastId == null ? 1 : lastId + 1;
	}

	/* 
		Note this won't work as an atomic transaction in SqLite. 
		When the time comes note that MySql allows deleting from multiple tables at once.
	 */
    async cascadeDelete(table,id)
    {
        const children = table.ownedTables();

        for (const child of children)
        {
			let select;
			if (child.hasMultiOwnerTable())
				/* Many-to-many table ownership: */
            	select = new DbSelect([child.idName()],table.name()).where('id','=');
			else
				/* Regular many-to-one ownership: */
            	select = new DbSelect(['id'],child.name()).where(table.idName(),'=');

	        const childIds = await this.column(select,[id]);

            for (const cid of childIds)
                await this.cascadeDelete(child,cid);
        }
        const del = new DbShallowDelete(table.name()).where('id','=');
        this.run(del,[id]);
    }

    async isEmpty()
    {
        return this.store==null;
    }

    async createTables()
    {
        const sqlDb = new SQL.Database();

        /* Run the CREATE TABLE commands: */
        for (var [name,table] of DbTables.all())
        {
            let cmd = "CREATE TABLE `"+name+"` (id INTEGER PRIMARY KEY ASC";
            for (let [fieldName,fieldType] of table.fields())
                cmd += ','+fieldName+' '+ fieldType.sqlType();
            for (let [fieldName,fieldType] of table.serverOnlyFields())
                cmd += ','+fieldName+' '+ fieldType.sqlType();
            cmd += ')';
            sqlDb.run(cmd);
        }

        this.store = sqlDb;
        this.storeCB(sqlDb);
    }

    /* Removes all data from the tables: */
    async clearData()
    {
        for (var [name,table] of DbTables.all())
        {
            let cmd = "DELETE FROM `"+name+"`;";
            this.store.run(cmd);
            this.storeCB(this.store);
        }
    }

    async dump()
    {
        let command = this.store.prepare('SELECT name FROM sqlite_master WHERE type="table"');
        const tables = [];
        for (; command.step(); )
            tables.push(Object.values(command.getAsObject())[0]);
        command.freemem();
        command.free();

        let ret = '';
        for (const table of tables)
        {
            ret += '--------- '+table+': ---------<br>\n';

            let command = this.store.prepare('SELECT * FROM `'+table+'`');
            for (; command.step(); )
                ret += JSON.stringify(command.getAsObject())+'<br>\n';
            command.freemem();
            command.free();
            ret += '<br>\n';
        }
        return ret;
    }


    /* Support routine: */
    async insertTestRow(tableClass,id,row)
    {
        let insert = new DbInsert(tableClass.name()).setToValue('id');
        let values = [id];

        const allFields = new Map([...tableClass.fields(),...tableClass.serverOnlyFields()]);
        for (let [fieldName,fieldType] of allFields)
        {
            insert = insert.setToValue(fieldName);
            const value = fieldType.jsToSql(row[fieldName]);
            values.push(value);
        }
        await this.run(insert,values);
    }
}

export default TestDb;
