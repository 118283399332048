import DbTable from './DbTable';
import Type from './DbFieldTypes';

class PasswordEmailTable extends DbTable
{
    constructor(db)
    {
        super('PasswordEmail',db);
        this.myFields = new Map([ ]);

		this.myServerOnlyFields = new Map([
                ['type',   	  Type.string()],  /* Valid values: 'rego'/'forgot' */
                ['email',	  Type.email().optional() ],
                ['key',   	  Type.string() ],
                ['created',   Type.string() ], 
                ['ip',  	  Type.string() ],
                ['firstName', Type.string() ],
                ['lastName',  Type.string() ]
			]);
    }

    async havePermission(user,opType,id,column)
    {
		return false;
    }
}

export default PasswordEmailTable;

