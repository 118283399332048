import Query from './Query';
import DbSelect from './DbSelect';
import DbTables from './DbTables';
import PermissionException from './PermissionException';

class EventLineupsQuery extends Query
{
    constructor(db)
    {
        super('EventLineups',db);
    }

	structure()
	{
		return {EventStage:{Lineup:{Set:{EventArtist:{}}}}};
	}

    async run(user,eventId) 
	{
        const userQuery = DbTables.get('Event').ownedRowsQuery(user).field('Event.id');
        if (await this.db.cell(userQuery,[])==null)
			throw new PermissionException();

//XXX can/should we reuse "ownedRowsQuery" below?


//XXX look in to MySql compression of result sets - may help with redundant columns:  https://dev.mysql.com/doc/internals/en/compression.html
//XXX NOTE this is currently assuming every lineup has a stage

//XXX cf how to communicate updates to Nunjucks. cf XPATH-like language.

		/*
			Note that there is the standard 1-to-many compositional relationship hierarchy between:
				EventStage <-- Lineup <-- Set
			EventArtist is owned by Event, not Set. There is a many-to-1 association between EventArtist and Set:
				EventArtist <-- Set
			which is usually going to be 1-to-1.
		 */

		const [stages,rowNormaliser] = this.normaliser();

		let query = new DbSelect([],'EventStage')
			.tableFields('EventStage',['id','name'])
			.tableFields('Lineup',['id','start','end'])
			.tableFields('Set',['id','start','end','position'])
			.tableFields('EventArtist',['id','name','tagline'])

			.leftJoin('EventStage.id','Lineup.eventStageId')
			.leftJoin('Lineup.id','Set.lineupId')
			.leftJoin('Set.eventArtistId','EventArtist.id')

			.where('EventStage.eventId','=') 
			.orderBy(['EventStage.name','EventStage.id','Lineup.start','Lineup.id','Set.position']);

		await this.db.each(query,[eventId],rowNormaliser);

console.log("stages:",stages);

        return stages;
    }
}

export default EventLineupsQuery; 
