import ManagerTable from './ManagerTable.js';
import PersonTable from './PersonTable.js';
import CompanyTable from './CompanyTable.js';
import EventTable from './EventTable.js';
import VenueTable from './VenueTable.js';
import DayAlertTable from './DayAlertTable.js';
import ArtistTable from './ArtistTable.js';
import VenueStageTable from './VenueStageTable.js';
import LineupTable from './LineupTable.js';
import SetTable from './SetTable.js';
import EventStageTable from './EventStageTable.js';
import EventArtistTable from './EventArtistTable.js';
import PasswordEmailTable from './PasswordEmailTable.js';
import AbuseTable from './AbuseTable.js';

class DbTables
{
	static init(db)
	{
		const companies = new CompanyTable(db);
		const events = new EventTable(db);
		const artists = new ArtistTable(db);
		const venues = new VenueTable(db);

		dbTableMap = new Map();
		addToMap(companies);
		addToMap(events);
		addToMap(artists);
		addToMap(venues);
		addToMap(new ManagerTable(db));
		addToMap(new PersonTable(db));
		addToMap(new DayAlertTable(db));
		addToMap(new VenueStageTable(db));
		addToMap(new EventStageTable(db));
		addToMap(new EventArtistTable(db));
		addToMap(new LineupTable(db));
		addToMap(new SetTable(db));
		addToMap(companies.ownerTable());
		addToMap(events.ownerTable());
		addToMap(artists.ownerTable());
		addToMap(venues.ownerTable());
		addToMap(new PasswordEmailTable(db));
		addToMap(new AbuseTable(db));

		/* Create links to owned tables for cascade deletes: */
		for (const [name,tab] of dbTableMap)
			if (tab.ownerTable()!=null)
				tab.ownerTable().myOwnedTables.push(tab);
	}

    static all()
    {
        return dbTableMap;
    }

    static get(name)
    {
        return dbTableMap.get(name);
    }

    /* Used in tests: */
    static setCustomTables(tableMap)
    {
        dbTableMap = tableMap;
    }
}

export default DbTables;

//---- Support functions-----

var dbTableMap = null;

function addToMap(item)
{
    dbTableMap.set(item.name(),item);
}
