import {EmailField,PasswordField} from '../DbFieldTypes';

export function submitRegistration(url)
{
	clearErrors(2,['firstname','lastname','email']);

	let problem = false;
	problem = checkField(2,'firstname',null,'First name must not be empty') || problem;
	problem = checkField(2,'lastname',null,'Last name must not be empty') || problem;
	problem = checkField(2,'email',new EmailField(),'An email address must be supplied') || problem;

	if (!problem)
		document.getElementById('form2').submit();
}

export function submitPassword()
{
	clearErrors('',['password','confirmPassword']);

	const problem = checkPasswordFields();

	if (!problem)
		document.getElementById('setPasswordForm').submit();
}

export function clearErrors(formId,fields)
{
	for (const field of fields)
		document.getElementById(field+formId+'FieldError').classList.remove('show');
}

function showError(formId,name,message)
{
	document.getElementById(name+formId+'FieldError').textContent = message;
	document.getElementById(name+formId+'FieldError').classList.add('show');
}

// Returns true is there IS a problem
function checkField(formId,name,field,noValueMessage)
{
	const value = document.getElementById(name+formId).value;

	if (value=='')
	{
		showError(formId,name,noValueMessage);
		return true;
	}

	if (field==null)
		return false;

	const error = field.validate(field.fromUserString(value)); 
    if (error!=null)
	    showError(formId,name,error.charAt(0).toUpperCase() + error.slice(1));

	return error!=null;
}

export function checkPasswordFields()
{
	let problem = checkField('','password',new PasswordField(),'A password must be supplied');

	/* Check the passwords match: */
	const password = document.getElementById('password').value.trim();
	const confirmPassword = document.getElementById('confirmPassword').value.trim();
	const prob = (password != confirmPassword);
	if (prob)
		showError('','confirmPassword','The passwords do not match')
	problem = problem || prob;

	return problem;
}

/* 
    zxcvbn is a large library due to its dictionary - only load it as needed 
 */
export function loadPasswordTestingLibrary()
{
    /* loadZxcvbn is a promise */
    if (!('loadZxcvbn' in window))
        window.loadZxcvbn = import('zxcvbn');
}

export function updatePasswordStrength()
{
    window.loadZxcvbn.then(({default:zxcvbn}) => {

        const password = document.getElementById('password').value;
        if (password.trim().length == 0)
            document.getElementsByClassName('passwordStrengthLine')[0].classList.remove('show');
        else
        {
            document.getElementsByClassName('passwordStrengthLine')[0].classList.add('show');

            const out = zxcvbn(password);

            document.getElementById('passwordStrength').value = out.score+1;
        }
    });
}

export default submitRegistration;

window.submitRegistration = submitRegistration;
window.submitPassword = submitPassword;
window.updatePasswordStrength = updatePasswordStrength;
window.loadPasswordTestingLibrary = loadPasswordTestingLibrary;
