import DbFieldType from './DbFieldType';
import FieldTypeException from './FieldTypeException';

//XXX some automated tests would be good here

//XXX For the moment I'm assuming that browser/REST API will provide the input in the best JS format - 
//    not a string, unless that is the best format.

export class StringField extends DbFieldType
{
//TODO if necessary add an option "trim:false"

//TODO consider: should we check maxLength in validate() and/or in jsToSql()?  In most (all?) cases MySql
//     does have a row size limit. Shouldn't rely on the fromUserString() as it can be bypassed.
//     Putting it in validate() might just waste time as fromUserString() has trimmed it anyway

	constructor(maxLength)
	{
		super();
		this.maxLength = maxLength;
	}

    validate(value) { 
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value.length > this.maxLength) return 'text too long';
        return null;
	}

    fromUserString(value) { 
        if (value.trim()=='') return null;
        return value.trim();
    }

	//XXX bit general?  Then again, currently only using for development
	sqlType() { return "TEXT"; }
}

export class BooleanField extends DbFieldType
{
    validate(value) { 
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='boolean') return 'not a Boolean'; 
        return null;
    }

	sqlType() { return "INTEGER"; }

    jsToSql(value) { return value ? '1' : '0'; }
}

export class UploadField extends DbFieldType
{
    validate(value) { 
        //TODO - see test in ArtistTable
        return null;
    }

	sqlType() { return "INTEGER"; }

    jsToSql(value) { return value==null || !value ? '0' : '1'; }

	sqlToJs(value) { return value=='1'; }

    fromUserString(value) { 
        if (value=='(delete)' || value=='')
            return false;
        return value;
    }

    viewData(value,env)
    {
        return value ? '(exists)' : '';
    }
}

export class PositiveIntegerOrZeroField extends DbFieldType
{
    validate(value) { 
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
        if (typeof value!='number' || !Number.isInteger(value) || value<0) return 'not a positive integer'; 
        return null;
    }

    fromUserString(value) { 
        if (value.trim()=='') return null;
        return Number(value.trim()) 
    }

	sqlType() { return "INTEGER"; }
}

//TODO sort out timezones
export class DateTimeField extends DbFieldType
{
//FIXME JS Date not consistent across browsers. See:
//  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse

    validate(value) 
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 

		/* The Date() constructor happily constructs an invalid date, hence the "getSeconds" check I'm using */
		if (!(value instanceof Date) || !('getSeconds' in value) || Number.isNaN(value.getSeconds()))
            return 'not a date-time';
        return null;
	}

	fromUserString(value) { 
        if (value.trim()=='') return null;
        return new Date(value.trim()); 
    }

	sqlType() { return "DATETIME"; }

    jsToSql(date) { 
        if (date==null) return null;
console.log('DATE:',date.toISOString());
		return date.toISOString(); 
	}

	sqlToJs(value) { return new Date(value); }
}

export class IdField extends DbFieldType
{
    validate(value) 
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='number' || !Number.isInteger(value) || value<0) return 'not an ID';
        return null;
	}

	fromUserString(value) 
	{
		if (value.trim()=='') return null;
		return Number(value.trim());
	}

	sqlType() { return "INTEGER"; }
}

export class EmailField extends DbFieldType
{
    validate(value)
	{
console.log('EmailField validate()  value:',value);
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 

		/* 
			This isn't a full check, and a full check isn't worth it as implementations are likely to vary
			from the standard from time to time.  Need to send an email to be sure anyhow.
		 */
		//TODO in time should check the domain name section contains only valid domain name characters
		if (value.match(/^\S+@\S+$/)==null) return 'invalid email address';
		if (value.length>256) return 'email too long';
        return null;
	}

    fromUserString(value) { 
		if (value.trim()=='') return null;
		return value.trim().slice(0,256); 
	}

	sqlType() { return "TEXT"; }
}

export class PasswordField extends DbFieldType
{
    validate(value) 
	{ 
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value.length<8) return 'password must be at least 8 characters long'; 
		if (value.match(/^\s.*/)!=null) return 'password must not start or end with a space';
		if (value.match(/.*\s$/)!=null) return 'password must not start or end with a space';
		if (value.length>8096) return 'password too long'; 
        return null;
	}

    fromUserString(value) { 
		if (value.trim()=='') return null;
        return value.trim().slice(0,8096); 
    }

	sqlType() { return "TEXT"; }
}

export class UrlField extends DbFieldType
{
    validate(value)
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		//TODO in time should check the domain name section contains only valid domain name characters
		//     (and same for email domains)
		if (value.match(/^https?:\/\/\S+$/)==null) return 'invalid URL';
		if (value.length>2100) return 'URL too long';
        return null;
	}

    fromUserString(value) { 
		if (value.trim()=='') return null;
		return value.trim().slice(0,2100); 
	}

	sqlType() { return "TEXT"; }
}

export class SpotifyField extends UrlField
{
    validate(value)
	{
        if (value==null && !this.required) return null; 
		const ret = UrlField.prototype.validate.call(this,value);
		if (ret!=null)
			return ret;
		if (value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/track\/.+/)!=null) 
			return null;
		if (value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/artist\/.+/)!=null ||
		    value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/album\/.+/)!=null) 
			return 'only song URLs here please, not album or artist URLs';
		return 'expected a Spotify song URL';
	}

    fromUserString(value) { 
		const bits = value.match(/^https?:\/\/(?:[^\/]+\.|)spotify\.com\/track\/(.+)/);
		if (bits!=null) 
			return 'https://play.spotify.com/track/'+bits[1];
		if (value.trim()=='') return null;
		return value.trim();
	}
} 

export class AppleField extends UrlField
{
    validate(value)
	{
        if (value==null && !this.required) return null; 
		const ret = UrlField.prototype.validate.call(this,value);
		if (ret!=null)
			return ret;
		if (value.match(/^https?:\/\/(?:[^\/]+\.|)apple\.com\/.+/)==null) 
			return 'expect an Apple Music URL';
		return null;
	}
} 

export class BandcampField extends UrlField
{
    fromUserString(value)
    {
		let matches = null;
		let ret = '';
		if ((matches = value.match(/(?:^|\W)(album=\w+)/))!=null)
			ret += matches[1];

		if ((matches = value.match(/(?:^|\W)(track=\w+)/))!=null)
		{
			if (ret!='')
				ret += '/';
			ret += matches[1];
		}
		return ret=='' ? null : ret;
    }

    validate(value)
	{
        if (value==null && !this.required) return null; 

		if (value.match(/^album=\w+\/track=\w+$/)!=null)
			return null;
		if (value.match(/^album=\w+$/)!=null)
			return null;
		if (value.match(/^track=\w+$/)!=null)
			return null;

		return "expected format 'album=123/track=123', 'track=123', or 'album=123'";
	}
}

export class SoundcloudField extends UrlField
{
    fromUserString(value)
    {
console.log('SoundcloudField.fromUserString()  value:',value);

		/* Example embed:
			<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" 
				src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/245380211&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
		 */

console.log('SoundcloudField.fromUserString() value:',value);

		let matches = null;
		if ((matches = value.match(/\Wapi\.soundcloud\.com\/([\w\/]+)/))!=null)
{
console.log('SoundcloudField.fromUserString() match:',matches[1]);
			return matches[1];
}
		if (value.match(/^[\w\/]+$/)!=null)
			return value;
		return null;
    }

    validate(value)
	{
        if (value==null && !this.required) return null; 

console.log('SoundcloudField.validate() value:',value);

		if (value.match(/^[\w\/]+$/)!=null)
			return null;

console.log('SoundcloudField.validate() - 2');

		return "expected formats include 'tracks/12345' and 'users/12345'";
	}
}

export class GenreField extends DbFieldType
{
    validate(value) 
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		/* NOTE: be sure to update the templates when genres change */
		if (value!='bluesRoots' &&
				value!='country' &&
				value!='dance' &&
				value!='electronica' &&
				value!='folk' &&
				value!='funkSoul' &&
				value!='hiphopRnb' &&
				value!='jazz' &&
				value!='metal' &&
				value!='pop' &&
				value!='psychedelic' &&
				value!='punk' &&
				value!='reggaeDub' &&
				value!='rock' &&
				value!='world' )
            return 'unknown genre'
        return null;
	}

	//XXX its not really a user string in this case - they are the values from the select
    fromUserString(value) { 
		if (value.trim()=='') return null;
        return value; 
    }

	sqlType() { return "TEXT"; }
}

export class MusicEmbedProviderField extends DbFieldType
{
    validate(value) 
	{
        /* Apple has an embed player, but it only plays 30 second samples. Not good enough. */
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value!='bandcamp' && value!='soundcloud')
            return 'unknown music player embed provider'
        return null;
	}

    fromUserString(value) { 
		if (value.trim()=='') return null;
        return value; 
    }

	sqlType() { return "TEXT"; }
}

export class VideoEmbedProviderField extends DbFieldType
{
    validate(value) 
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value!='youtube' && value!='vimeo' && value!='facebookVideo')
            return 'unknown video embed provider'
        return null;
	}

    fromUserString(value) { 
		if (value.trim()=='') return null;
        return value; 
    }

	sqlType() { return "TEXT"; }
}

export class FacebookField extends DbFieldType
{
    validate(value)
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value.match(/^[^\/\s&]+$/)==null) return 'invalid Facebook identifier';
		if (value.length>128) return 'Facebook identifier too long';
        return null;
	}

    fromUserString(value) 
    { 
        //TODO? Could test UID using:  http://graph.facebook.com/[ID]  Maybe even show a preview
        if (value.trim()=='') return null;

        var match1 = /^\s*https?:\/\/(?:www\.)?facebook\.com\/([^\/\s&]+)/.exec(value);
        if (match1!=null)
            return match1[1];

        var match2 = /^\s*@?([^\/\s]+)/.exec(value);
        if (match2!=null)
            return match2[1];

        /* This should fail during validation: */
        return value;       
	}

	sqlType() { return "TEXT"; }
}

export class TwitterField extends DbFieldType
{
    validate(value)
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value.match(/^[^\/\s\?#&]+$/)==null) return 'invalid Twitter handle';
		if (value.length>128) return 'Twitter handle too long';
        return null;
	}

    fromUserString(value) 
    { 
        if (value.trim()=='') return null;

        var match1 = /^\s*https?:\/\/(?:www\.)?twitter\.com\/([^\/\s\?#&]+)/.exec(value);
        if (match1!=null)
            return match1[1];

        var match2 = /^\s*@?([^\/\s]+)/.exec(value);
        if (match2!=null)
            return match2[1];

        /* This should fail during validation: */
        return value;       
	}

	sqlType() { return "TEXT"; }
}

export class InstagramField extends DbFieldType
{
    validate(value)
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value.match(/^[^\/\s\?#&]+$/)==null) return 'invalid Instagram identifier';
		if (value.length>128) return 'Instagram identifier too long';
        return null;
	}

    fromUserString(value) 
    { 
        if (value.trim()=='') return null;

        var match1 = /^\s*https?:\/\/(?:www\.)?instagram\.com\/([^\/\s\?#&]+)/.exec(value);
        if (match1!=null)
            return match1[1];

        var match2 = /^\s*@?([^\/\s]+)/.exec(value);
        if (match2!=null)
            return match2[1];

        /* This should fail during validation: */
        return value;       
	}

	sqlType() { return "TEXT"; }
}

export class YoutubeField extends DbFieldType
{
    validate(value)
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value.match(/^[^\/\s#&]+$/)==null) return 'invalid YouTube identifier';
		if (value.length>256) return 'YouTube identifier too long';
        return null;
	}

    fromUserString(value) 
    { 
        if (value.trim()=='') return null;

		var match1 = /https?:\/\/(?:www\.)?(?:youtu\.be\/|youtube\.com\/embed\/|youtube\.com\/watch\?v=)([^\/"'\s\#]+)/.exec(value);

        // NOTE the time parameter will be included, as will any other parameters, if they exist.
        //      This may not be desirable, although including the time probably is.
        if (match1!=null)
            return match1[1];

        /* This should fail during validation: */
        return value;       
	}

	sqlType() { return "TEXT"; }
}

export class VimeoField extends DbFieldType
{
    validate(value)
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value.match(/^[^\/\s#&]+$/)==null) return 'invalid Vimeo identifier';
		if (value.length>256) return 'Vimeo identifier too long';
        return null;
	}

    fromUserString(value) 
    { 
        if (value.trim()=='') return null;

        /* Matches:
            1.  https://vimeo.com/17972817
            2.  <iframe src="https://player.vimeo.com/video/17972817" width="640" height="360" ...
         */
		var match1 = /https?:\/\/(?:[^.\/]+\.)?vimeo\.com\/([0-9]+)/.exec(value);
        if (match1!=null)
            return match1[1];

        /* This should fail during validation: */
        return value;       
	}

	sqlType() { return "TEXT"; }
}

export class FacebookVideoField extends DbFieldType
{
    validate(value)
	{
        if (value==null && !this.required) return null; 
        if (value==null) return 'field is required'; 
		if (typeof value!='string') return 'not a string'; 
		if (value.match(/^[^\/\s#&]+$/)==null) return 'invalid Facebook Video identifier';
		if (value.length>256) return 'Facebook Video identifier too long';
        return null;
	}

    fromUserString(value) 
    { 
        if (value.trim()=='') return null;
console.log('FacebookVideoField.fromUserString()  value:',value);

        /* Valid formats:
            https://www.facebook.com/watch/?v=10153949778421332
            https://www.facebook.com/abbemay/videos/2305474926170084/ 
         */
		var match1 = /https?:\/\/www\.facebook\.com\/watch\/\?v=([0-9]+)/.exec(value);
        if (match1!=null)
{
console.log('FacebookVideoField.fromUserString()  match1:',match1[1]);
            return match1[1];
}

console.log('FacebookVideoField.fromUserString() - 2');


        var match2 = /https?:\/\/www\.facebook\.com\/[^\/]+\/videos\/([0-9]+)/.exec(value);
        if (match2!=null)
{
console.log('FacebookVideoField.fromUserString()  match2:',match2[1]);
            return match2[1];
}

console.log('FacebookVideoField.fromUserString()  - 3');

        /* This should fail during validation: */
        return value;       
	}

	sqlType() { return "TEXT"; }
}

export class FacebookVideoUrlField extends UrlField
{
    validate(value)
	{
        if (value==null && !this.required) return null; 

        /* Valid formats:
            https://www.facebook.com/watch/?v=10153949778421332
            https://www.facebook.com/abbemay/videos/2305474926170084/ 
         */
		if (/https?:\/\/www\.facebook\.com\/watch\/\?v=[0-9]+/.exec(value) != null)
			return null;
        if (/https?:\/\/www\.facebook\.com\/[^\/]+\/videos\/[0-9]+/.exec(value) != null)
			return null;
        return 'not a Facebook video URL';
	}
}

export class Type 
{
	static string(maxLength=1024) 	{ return new StringField(maxLength); }
	static boolean() 				{ return new BooleanField(); }
	static positiveIntegerOrZero()  { return new PositiveIntegerOrZeroField(); }
	static dateTime() 			    { return new DateTimeField(); }
	static id()					    { return new IdField(); }
	static url()					{ return new UrlField(); }
	static email()				    { return new EmailField(); }
	static password()				{ return new PasswordField(); }
	static key()					{ return new StringField(512); }
	static abuseType()			    { return new StringField(16); }
	static stageOwner()			    { return new StringField(4); }  //FIXME current valid values are V and E
	static positiveInteger()		{ return new PositiveIntegerOrZeroField(); }  //FIXME a version without 0
	static genre()				    { return new GenreField(); }
	static facebook()				{ return new FacebookField(); }
	static twitter()				{ return new TwitterField(); }
	static instagram()				{ return new InstagramField(); }
	static youtube()				{ return new YoutubeField(); }
	static vimeo()	    			{ return new VimeoField(); }
	static facebookVideo()			{ return new FacebookVideoField(); }
	static upload()  	            { return new UploadField(); }
	static spotify()				{ return new SpotifyField(); }
	static apple()				    { return new AppleField(); }
	static musicEmbedProvider()		{ return new MusicEmbedProviderField(); }
	static videoEmbedProvider()		{ return new VideoEmbedProviderField(); }
	static bandcamp()				{ return new BandcampField(); }
	static soundcloud()				{ return new SoundcloudField(); }
	static facebookVideoUrl()		{ return new FacebookVideoUrlField(); }
}

export default Type;
