class DbId
{
	constructor(name)
	{
		this.name = name;
	}

	static am(value)
	{
		return value instanceof DbId;
		//return typeof value=='object' && value!=null && value instanceof DbId;
	}
}

export default DbId;
