import DbTable from './DbTable';
import DbTables from './DbTables';
import Type from './DbFieldTypes';

class EventManagerTable extends DbTable
{
    constructor(db)
    {
        super('EventManager',db);
        this.myFields = new Map([
            ]);
        this.myServerOnlyFields = new Map([
                ['managerId', Type.id() ],
                ['eventId',   Type.id() ] 
			]);
    }

    ownerTable()
    {
        return DbTables.get('Manager');
    }

    testData()
    {
        return  [
            {id:0, managerId:0, eventId:0 },
            {id:1, managerId:0, eventId:1 },
            {id:2, managerId:0, eventId:2 }
        ];
    }
}

export default EventManagerTable;
