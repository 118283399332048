import Component from './Component';
import DbTables from './DbTables';

class List extends Component
{
	constructor(instanceName,queryClass)
	{
		super(instanceName);
		this.table = [];
		this.id = null;
		this.queryClass = queryClass;
	}

    getDisplayData()
    {
        return this.table;
    }

    load()
    {
        return db.query(this.queryClass.name(),this.id).then(value => this.table = value );
    }

	changeIndex(mode,id)
	{
		/* The last result is cached.  Return if the ID hasn't changed. */
		if (id==this.id)
			return;
		this.id = id;
		this.needsLoad = true;
	}

	handleDbUpdate(table,operation,id,data)
	{
        if (operation!='UPDATE_ROW' && operation!='CREATE_ROW' && operation!='DELETE_ROW')
            return false;

		const guides = createTableTreeGuides(this.queryClass.structure(),table);
		if (guides.length==0)
			return false;

//XXX just show/pass through rows with permission?
//    In browser:  can probably only be logged in as one user, so probably not a problem
//    From server: probably need to filter out relevant updates. Admins would get a lot...
//                 may need to subscribe by page or disable updates


		if (operation=='UPDATE_ROW')
		{
			let changed = false;
			for (const guide of guides)
				/* createTableTreeGuides checks the top table */
				if (doUpdateData(guide.slice(1),id,this.table,data))
					changed = true;
			return changed;
		}
		if (operation=='CREATE_ROW')
		{
			let changed = false;
			for (const guide of guides)
			{
				if (table==guide[0])
				{
					/* In top-level cases add everything */
					this.table.push(data);
					changed = true;
				}
				else
				{
					/* createTableTreeGuides checks the top table */
					const parentIdName = getParentIdName(this.queryClass.structure(),table);
					const ownIdName = DbTables.get(table).idName();
					if (doAddData(guide.slice(1),table,parentIdName,ownIdName,this.table,data))
						changed = true;
				}
			}
			return changed;
		}
		if (operation=='DELETE_ROW')
		{
			let changed = false;
			for (const guide of guides)
				/* createTableTreeGuides checks the top table */
				if (doDeleteData(guide.slice(1),this.table,id))
					changed = true;
			return changed;
		}
	}
}

export default List;


/* ------------ Support routines: ------------- */

function getParentIdName(struct,tableName)
{
	const name = doGetParentIdName(struct,tableName,null);
	return DbTables.get(name).idName();
}

function doGetParentIdName(struct,tableName,parentName)
{
	for (const [name,property] of Object.entries(struct))
	{
		if (name == tableName)
			return parentName;
		else
		{
			const ret = doGetParentIdName(property,tableName,name);
			if (ret!=null)
				return ret;
		}
	}
}

/* Returns the paths through the table tree structure to a particular table.  */
export function createTableTreeGuides(struct,tableName)
{
	if (tableName in struct)
		return [[tableName]];

	const paths = [];
	for (const [name,substruct] of Object.entries(struct)) 
	{
		const subpaths = createTableTreeGuides(substruct,tableName);
		for (let path of subpaths)
		{
			path.unshift(name);  //ie prepend
			paths.push(path);
		}
	}
	return paths;
}

/* Return true if something has been changed */
function doUpdateData(guide,id,dataTree,newEntry)
{
	let changed = false;

	for (let row of dataTree)
	{
		if (guide.length>0)
		{
			if (doUpdateData(guide.slice(1),id,row[guide[0]],newEntry))
				changed = true;
		}
		else if (row.id==id)
		{
			for (const [fieldName,fieldValue] of Object.entries(newEntry))
				row[fieldName] = fieldValue;
			return true;  //Assumes IDs are unique within one leaf list
		}
	}
	return changed;
}

/* Return true if something has been changed */
function doAddData(guide,tableName,parentIdName,ownIdName,dataTree,newEntry)
{
	let changed = false;

	if (guide.length==1) 
		for (let row of dataTree)
		{
//XXX for top-level addition there is no ID check, but we do need permission. 
//    We should only be served with items we have permission for though (check in DbTransactor or BrowserTestDb)
			if (row.id == newEntry[parentIdName])
			{
				const copy = Object.assign(newEntry);
				delete copy[parentIdName];
				row[tableName].push(copy);
				changed = true;
			}
			else if (newEntry.id == row[ownIdName])
			{	/* Adding a parent table in the join-create case: */
				row[tableName] = [newEntry];
				delete row[ownIdName];
				changed = true;
			}
		}
	else if (guide.length>1)
		for (let row of dataTree)
			if (doAddData(guide.slice(1),tableName,parentIdName,ownIdName,row[guide[0]],newEntry))
				changed = true;

	return changed;
}

/* Return true if something has been changed */
function doDeleteData(guide,dataTree,id)
{
	if (guide.length==0)
	{
		for (let i=0; i<dataTree.length; i++)
			if (dataTree[i].id == id)
				dataTree.splice(i,1);
		return true;
	}

	let changed = false;
	for (let row of dataTree)
	{
		if (doDeleteData(guide.slice(1),row[guide[0]],id))
			changed = true;
	}
	return changed;
}

