import DbTable from './DbTable';
import Type from './DbFieldTypes';

class ManagerTable extends DbTable 
{
    constructor(db)
    {
        super('Manager',db);
        this.myFields = new Map([
            ]);

		this.myServerOnlyFields = new Map([
			]);
    }

    testData()
    {
        return  [
            {id:0  },
            {id:1  },
            {id:2  },
            {id:3  }
        ];
    }

	amPermissionRoot()
	{
		return true;
	}

    async havePermission(user,opType,id,column)
    {
		switch(opType)
		{
			case 'CREATE_ROW':
			case 'DELETE_ROW':
				return user.amAdmin();
			case 'UPDATE_ROW':
			case 'READ_ROW':
				return id==user.managerId() || user.amAdmin(); 
		}
		throw new Error('Unsupported operation: '+opType);
    }
}

export default ManagerTable;

