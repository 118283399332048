import DbTable from './DbTable';
import DbTables from './DbTables';
import Type from './DbFieldTypes';

class VenueManagerTable extends DbTable
{
    constructor(db)
    {
        super('VenueManager',db);
//XXX consider setting myFields in super()... its pretty standard
        this.myFields = new Map([
            ]);

        this.myServerOnlyFields = new Map([
                ['managerId', Type.id() ],
                ['venueId',   Type.id() ]
			]);
    }

    ownerTable()
    {
        return DbTables.get('Manager');
    }

    testData()
    {
        return  [
            {id:0, managerId:0,venueId:0 },
        ];
    }
}

export default VenueManagerTable;

