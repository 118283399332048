import PageParent from './PageParent';
import List from './List';
import DbQueries from './DbQueries';

class ArtistListPage extends PageParent
{
    constructor()
    {
        super('ArtistList','artistListPage.njk');
		this.list = new List('artists',DbQueries.get('ArtistList'));
		this.addComponent(this.list);
    }

	delete(id)
	{
        db.startTransaction();
        db.deleteRow('Artist',id);
        db.finishTransactionNoReturnValues();

	}
}

export default ArtistListPage;
