import DbTable from './DbTable';
import Type from './DbFieldTypes';
import EventManagerTable from './EventManagerTable';

class EventTable extends DbTable
{
	constructor(db)
	{
		super('Event',db);
		this.ownersTable = new EventManagerTable(db);

//XXX maybe add a field "type": music, menu, ...
		this.myFields = new Map([
				['name',  Type.string() ],
				['start', Type.dateTime() ],
				['end',   Type.dateTime() ]
			]);
	}
	
//TODO probably want recurring events too
//XXX store in UTC? or local time? Local time probably simpler... TODO add timezone field
	testData()
	{
		return  [
			{id:0, start:new Date('2018-03-10T20:00:00'), end:new Date('2018-03-11T00:00:00'), name:'Dirty Rock Night' },
			{id:1, start:new Date('2018-03-10T22:00:00'), end:new Date('2018-03-11T00:00:00'), name:'Metal Night' },
			{id:2, start:new Date('2018-03-09T14:00:00'), end:new Date('2018-03-12T20:00:00'), name:'Festival of Frogs' }
		];
	}

    hasMultiOwnerTable()
    {
        return true;
    }

	ownerTable()
	{
		return this.ownersTable; 
	}
}

export default EventTable; 
