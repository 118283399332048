/*
	"fieldName" is only required when displaying an error under a GUI field.
 */
function FieldTypeException(message,fieldName)
{
	this.message = message;
	this.name = 'FieldTypeException';
	this.stack = (new Error()).stack;  //XXX perhaps only include when debugging
	this.field = fieldName;
}

export default FieldTypeException;
