import DbTable from './DbTable';
import Type from './DbFieldTypes';
import DbSelect from './DbSelect';
import DbInsert from './DbInsert';
import DbCascadeDelete from './DbCascadeDelete';
import DbId from './DbId';
import CompanyPersonTable from './CompanyPersonTable';

class CompanyTable extends DbTable
{
    constructor(db)
    {
        super('Company',db);
		this.ownersTable = new CompanyPersonTable(db);

        this.myFields = new Map([
                ['name', Type.string() ]
            ]);

		this.myServerOnlyFields = new Map([
                ['managerId', Type.id() ],
			]);
    }

    hasMultiOwnerTable()
    {
        return true;
    }

	ownerTable()
	{
		return this.ownersTable;
	}

    testData()
    {
		/* To generate these hashes in the 'server' directory run:  "node createPassword.js" */

		return [
			{id:5,managerId:1,name:'Artists Fartists'},
		];
    }

	async beforeCreate(user,row,idVariable,newRowIds)
	{
		const insert = new DbInsert('Manager');
		const newId = await this.db.insert(insert,[]); 
		return {managerId: newId};
	}

	async beforeCascadeDelete(user,id)
	{
		/* These would be better combined using a join */
		const select = new DbSelect(['managerId'],'Company').where('id','=');
		const managerId = await this.db.cell(select,[id]);

		const deleteOp = new DbCascadeDelete('Manager').where('id','=');
		await this.db.run(deleteOp,[managerId]);
	}
}

export default CompanyTable;
