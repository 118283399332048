import PageParent from './PageParent';
import Form from './Form';
import LineupTable from './LineupTable';

class LineupPage extends PageParent
{
    constructor()
    {
        super('Lineup','lineupPage.njk');
        this.form = new Form('form','#form',['Lineup'],[]);
		this.addComponent(this.form);
	}

    changePage(context,next,mode)
    {
        this.eventId = Number(context.params.eid);
console.log("LineupPage eventId=",this.eventId,"id=",Number(context.params.id),"pid=",[Number(context.params.pid)]);
        this.form.changeIndex(mode,Number(context.params.id),[Number(context.params.pid)]);
    }

//XXX could possibly create a common parent for all the little forms on the Event page
    submit(e)
    {
        e.preventDefault();
        this.form.save().then(() => toPage('/event/'+this.eventId));
    }

    delete(e)
    {
        e.preventDefault();
        this.form.delete().then(() => toPage('/event/'+this.eventId));
    }
}

export default LineupPage;
