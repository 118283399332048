import PageParent from './PageParent';
import { pageUpdate } from './toPage';
import DbId from './DbId';

class CalendarPage extends PageParent 
{
	constructor() 
	{
		super('Calendar');
console.log("CalendarPage - constructor");
		/* The values a Booleans. true=>creating, false=>deleting */
		this.pending = new Map(); 

		this.toggleAlert = this.toggleAlert.bind(this); 

		/* XXX
			Note that because I'm creating all the *Page objects as singletons at start-up this
		   	will likely lead to a lot of template files being loaded in at start up.
		 */
		this.pageTemplate = window.nunjucksEnv.getTemplate('calendarPage.njk',true); 
		this.eventsTemplate = window.nunjucksEnv.getTemplate('calendarPageEvents.njk',true);
console.log("CalendarPage - constructor - END");
	}

	/*
		An option would be to separate the alerts part and the events parts into two component classes
		and call them from this class. Note that the template itself is shared though - they only really
		look like two components behind the scenes.
     */

	syncDayAlert(dayNum)  //XXX using node would reduce the need to do extra JS binding
	{
		const alerts = this.alertsByDay;
        const days = document.getElementsByClassName('day'); // May not be too efficient if called repeatedly
        const node = days[dayNum];

        if (alerts.has(dayNum))
            node.classList.add('dayAlert');
        else
            node.classList.remove('dayAlert');

        if (this.pending.has(dayNum))
            node.classList.add('pending');
        else
            node.classList.remove('pending');
	}

	syncAllDayAlerts()
	{
		for (var [day,value] of this.alertsByDay)
			this.syncDayAlert(day);
	}

	syncDayEvent(dayNum)
	{
        const days = document.getElementsByClassName('day'); // May not be too efficient if called repeatedly
        const node = days[dayNum].getElementsByClassName('calendarDayEvent')[0];

		var str = this.eventsTemplate.render({ events: this.eventsByDay.get(dayNum) });
		node.innerHTML = str;
	}

	syncAllEvents()
	{
		for (var [day,value] of this.eventsByDay)
			this.syncDayEvent(day);
	}

	toggleAlert(e,dayNum) 
	{
console.log('toggleAlert  dayNum:',dayNum,'  alertsByDay:',this.alertsByDay);
		const alerts = this.alertsByDay;
		if (alerts.has(dayNum))
		{
			const myAlert = alerts.get(dayNum);
//XXX cf reusing any of this pending() mechanism?
			if (this.pending.has(dayNum))
				return;

			const id = myAlert.id;
			this.pending.set(dayNum,false);

console.log('toggleAlert  SENDING DELETE:');

			db.startTransaction();
			db.deleteRow('DayAlert',id);
			db.finishTransaction().then(() => {
					alerts.delete(dayNum);
					this.pending.delete(dayNum);
					this.syncDayAlert(dayNum);
				});
		}
		else
		{
			alerts.set(dayNum,{day:dayNum});  
				//TODO call a create routine? 
			this.pending.set(dayNum,true);

console.log('toggleAlert  SENDING CREATE:');

			db.startTransaction();
			db.createRow('DayAlert',{day:dayNum,managerId:window.managerId},new DbId('DayAlert.id'));
			db.finishTransaction().then((changes,variables) => {
					alerts.get(dayNum).id = variables['DayAlert.id'];
					this.pending.delete(dayNum);
					this.syncDayAlert(dayNum);
				});
		}

		this.syncDayAlert(dayNum);
	}

//XXX this is overriding Page.handleDbUpdates() Can we use components instead?
	handleDbUpdates(visible,updates)
	{
		for (const u of updates)
			this.handleDbUpdate(visible,u.table,u.operation,u.id,u.row)
	}

	handleDbUpdate(visible,table,operation,id,data)
	{
		if (table=='DayAlert')
		{	
			var day;
			if (operation == 'CREATE_ROW') //XXX can this be shared with code above?
			{
				day = data.day;
				this.alertsByDay.set(day,{id:id,day:day});  //XXX create function?
			}
			else
				for (var [key,a] of this.alertsByDay)  //XXX cf making a helper function
					if (a.id==id)
					{
						day = a.day;
						this.alertsByDay.delete(key);
						break;
					}
			if (visible)
				this.syncDayAlert(day);
			return;
		}

		if (table=='Event')
		{	
			//XXX coarse update: 
			//XXX May want a special transition for DB updates
//			this.setNeedsLoad();  //FIXME
			if (visible)
				pageUpdate(this.load(), this.display);
			return;
		}
	}

	display()
	{
console.log("CalendarPage - display() - 1");

//XXX can I auto-include "person" (and maybe context too?)
//XXX put user info in some header template
		var str = this.pageTemplate.render({person: window.person}); 
        document.getElementById('content').innerHTML = str;
		this.syncAllEvents();
		this.syncAllDayAlerts();
console.log("CalendarPage - display() - 2");
	}

	load() 
	{
console.log("CalendarPage - load() - 1");
		return db.query('Calendar').then(data => {
console.log("CalendarPage - load() - 2");
				this.alertsByDay = data.alertsByDay;
				this.eventsByDay = data.eventsByDay;
console.log("CalendarPage - load() - 3");
			});
	}
}

export default CalendarPage;
