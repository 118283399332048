import PageParent from './PageParent';
import Form from './Form';
import PersonTable from './PersonTable';
import {checkPasswordFields,clearErrors} from './server/loginPage';

class UserPage extends PageParent
{
    constructor()
    {
        super('User','userPage.njk');
        this.form = new Form('form','#form',['Person'],[]);
		this.addComponent(this.form);
    }

    changePage(context,next,mode)
    {
		window.loadPasswordTestingLibrary();
        this.form.changeIndex(mode,Number(context.params.id),[null]);
    }

//XXX consider implementing a SingleFormPage class & inheriting from that
    submit(e)
    {
        e.preventDefault();
        this.form.save().then(() => toPage('/user/'+this.form.id(),null));
    }

	delete(e)
	{
        e.preventDefault();
//FIXME return to caller.  Does page.js keep a record?
        this.form.delete().then(() => toPage('/users',null) );
	}

	disconnectProvider(provider)
	{
		db.misc('DISCONNECT_PROVIDER',{provider:provider}).then(data => {
				if ('error' in data)
					alert(data.error);
				else
				{						
					this.form.needsLoad = true;  //XXX may not be necessary
					this.form.loadedBaseTableId = -1;  //XXX poxy
					this.load().then( () => this.display() );
				}						
			});
	}

	changePassword()
	{
console.log('Sending CHANGE_PASSWORD');			
		clearErrors('',['password','confirmPassword']);
		const problem = checkPasswordFields();
		if (problem)
			return;

		const currentPasswordNode = document.getElementById('currentPassword');
		const currentPassword = currentPasswordNode==null ? null : currentPasswordNode.value;
		db.misc('CHANGE_PASSWORD',
					{password:document.getElementById('password').value,
					currentPassword:currentPassword})
			.then(data => {
				if ('error' in data)
					alert(data.error);
				else
				{						
					alert('Password updated');
					this.form.needsLoad = true;  //XXX may not be necessary
					this.form.loadedBaseTableId = -1;  //XXX poxy
					this.load().then( () => {
							this.display();
							document.getElementById('passwordFormContainer').classList.add('hideForm');
						});
				}						
			});
	}
}

export default UserPage;
