import Query from './Query';
import DbTables from './DbTables';

class EventsOnDayQuery extends Query
{
    constructor(db)
    {
        super('EventsOnDay',db);
    }

	structure()
	{
		return {Event:{}};
	}

	async run(user,dayNum)
	{
		const day = dayNum<10 ? '0'+dayNum : ''+dayNum;

		const table = DbTables.get('Event');
		const query = table.ownedRowsQuery(user)
						.fields(['Event.id','start','end','name'])
						.whereUnsafe("strftime('%d',start)",'=');

		const ret = [];
		for (let i of await this.db.table(query,[day]))	
			ret.push(i);
		return ret;
	}
}

export default EventsOnDayQuery; 
