class Query
{
	constructor(name,db)
	{
        this.myName = name;
		this.db = db;
	}

	name()
	{
        return this.myName;
	}

	/* 
		Returns an empty structure containing the tables as nested in the query.
		Leafs should use empty objects.
		The order and nesting should reflect that in the query and thus allow the renormalisation and
		use of the data.
	 */
	structure()
	{
	}

	async run(user)
	{
	}

	/* Convenience wrapper for normaliser, below */
	normaliser()
	{
		return normaliser(this.structure());
	}
}

export default Query;


/*
	Normalises the results of a database query.
	Returns a callback that takes a row at a time and the resulting data structure.
	XXX currently only accepts linear paths - see some notes on this below.
 */
export function normaliser(structure)
{
	/* Convert the structure to an array: */
	const struct = [];
	let current = structure;
	while (true)
	{
		const entries = Object.entries(current);
		if (entries.length==0)
			break;
		if (entries.length>1)
			throw new Error('Currently only linear structures are supported');
		struct.push(entries[0][0]);
		current = entries[0][1];
	}

	/* Initialise stores: */
	const ids = new Map();
	const trees = new Map();
	for (const tableName of struct)
	{
		ids.set(tableName,null);
		trees.set(tableName,[]);
	}

	/* Create a callback that takes a row at a time */
	const callback = (row) => {
		for (let i=0; i<struct.length; i++)
		{
			const table = struct[i];
			const id = row[table+'__id'];

			if (id!=null && ids.get(table)!==id)
			{
				/* We are in a new entry at this level in the data structure: */
				ids.set(table,id);
				for (let j=i+1; j<struct.length; j++)
					ids.set(struct[j],null);

				const prefix = table+'__';
				const entry = {};
				for (const [fieldName,fieldValue] of Object.entries(row))
					if (fieldName.startsWith(prefix))
						entry[fieldName.slice(prefix.length)] = fieldValue;

				/* Start the next nested structure: */
				if (i<struct.length-1)
				{
					const nextTableName = struct[i+1];
					const nextTree = [];
					trees.set(nextTableName,nextTree);
					entry[nextTableName] = nextTree;
				}

				trees.get(table).push(entry);
			}
		}
	}
	return [trees.get(struct[0]),callback];
}

/* Same thing as normaliser, but runs over a fully loaded table  */
export function normalise(structure,table)
{
	const [output,callback] = normaliser(structure);
	for (const row of table)
		callback(row);
	return output;
}

/*	
	Some notes on normalisation
	---------------------------
	Do we want or need to support normalisation in cases where a query is not a linear path?

	Consider the simplest such case:
		SELECT * FROM A INNER JOIN B ON A.id=B.aId INNER JOIN C ON A.id=C.aId WHERE a.id=69
	Note we have A as parent of both B and C, we are specifying our WHERE clause using the parent
	and we are using INNER JOINs.

	In this case it appears that we will end up with a cross-product of BxC. Generally we will want
	to be avoiding cross products.

	FURTHER NOTES: it appears that in general the ordering of inner join clauses doesn't matter
	and outer joins don't matter except with respect to NULL values. I have only really considered
	cases where the WHERE clause tests a single ID.
 */
