import UserPage from './UserPage';
import UserListPage from './UserListPage';
import CalendarPage from './CalendarPage';
import DiaryPage from './DiaryPage';
import EventPage from './EventPage';
import ArtistListPage from './ArtistListPage';
import ArtistPage from './ArtistPage';
import EventArtistPage from './EventArtistPage';
import EventStagePage from './EventStagePage';
import LineupPage from './LineupPage';
import SetPage from './SetPage';
import CompanyListPage from './CompanyListPage';
import CompanyPage from './CompanyPage';

class Pages
{
	static init()
	{
console.log("Pages.init()");
		map = new Map();
		addToMap(new UserPage());
		addToMap(new UserListPage());
		addToMap(new CompanyPage());
		addToMap(new CompanyListPage());
		addToMap(new CalendarPage());
		addToMap(new EventPage());
		addToMap(new DiaryPage());
		addToMap(new ArtistListPage());
		addToMap(new ArtistPage());
		addToMap(new EventArtistPage());
		addToMap(new EventStagePage());
		addToMap(new LineupPage());
		addToMap(new SetPage());
	}

    static all()
    {
console.log("Pages.all()");
        if (map==null)
            this.init();
        return map;
    }

    static get(name)
    {
        if (map==null)
            this.init();
        return map.get(name);
    }
}

export default Pages;

//---- Support functions-----

var map = null;

function addToMap(item)
{
    map.set(item.name(),item);
}
