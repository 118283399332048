import {pageUpdate} from './toPage';
import Pages from './Pages';
//import DatabindExtension from './DatabindExtension'; 

//import registerServiceWorker from './registerServiceWorker';

import nunjucks from 'nunjucks';
import dateFilter from 'nunjucks-date-filter';
		// OR MAYBE nunjucks-timezone-aware-datefilter OR nunjucks-date

const page = require('page');
//import page from 'page';  <== causing problems

window.nunjucksEnv = nunjucks.configure({
		trimBlocks:true,
		lstripBlocks:true
		//throwOnUndefined:true
	});
nunjucksEnv.addFilter('date', dateFilter);
nunjucksEnv.addFilter('ran', function(max) {
	return Math.floor(Math.random() * max);
});

//XXX dont really want to have to create all page objects at the start... Having them as globals is a bit daggy too
//XXX maybe put in page constructor or init?  NB only one want one copy.


//FIXME create a switch to turn the dummy server on and off...
import ConnectionToDummyServer from './ConnectionToDummyServer';
//window.db = new ConnectionToDummyServer();  //FIXME this functionality needs testing again

import SocketIoConnectionToServer from './SocketIoConnectionToServer';
window.db = new SocketIoConnectionToServer();
db.init();

//FIXME can I guarantee no updates will come in between DB creation and listening.  cf
//      some kind of "connect()" method that gets called after creating DB

/* 
	Create global page objects (e.g. "window.userPage") so templates can access them.
	Also register them as DB listeners.
 */
for (let [name,page] of Pages.all())
{
	const global = name.charAt(0).toLowerCase() + name.slice(1) + 'Page';
	window[global] = page;
	db.registerListeningPage(page);
}

console.log('In index.js - 2');

//Organiser.run();


//TODO add page transitions (150ms fade)

//TODO Also start precompiling Nunjucks (at least for production...)
//TODO Nunjucks - is source-mapping a thing?
//XXX the "src" directory should probably be largely private

//XXX if we server-generate the first page we should make sure no DB updates can sneak in
//    before the first rendering.

/* Get user data */
const personPromise = db.readRow('Person',window.personId,[]);

console.log('In index.js - 3 window.personId=',window.personId);

/* "page.js" appears to intercept URLs in <a href="xxx" ...> */

//XXX probably need the user information confirmed before we can really start anything:
/* Router: */
personPromise
	.then( p => {
console.log('In index.js - 5');
		window.person = p.Person;
console.log('person:',p);

		page('/','/artists');
		page('/users', goToPage('UserList'));
		page('/user/:id/edit', goToPage('User','edit'));
		page('/user/new', goToPage('User','create'));
		page('/user/:id', goToPage('User','view'));
		page('/organisations', goToPage('CompanyList'));
		page('/organisation/:id/edit', goToPage('Company','edit'));
		page('/organisation/new', goToPage('Company','create'));
		page('/organisation/:id', goToPage('Company','view'));
		page('/events', goToPage('Calendar'));
//		page('/event/:eid/artist/new', goToPage('EventArtist','create'));
		page('/event/:eid/lineup/:pid/set/new', goToPage('Set','create'));
		page('/event/:eid/set/:id/edit', goToPage('Set','edit'));
//		page('/event/:eid/set/:id', goToPage('Set','view'));
		page('/event/:pid/artist/:id', goToPage('EventArtist','view'));
		page('/event/:eid/stage/:pid/lineup/new', goToPage('Lineup','create'));
		page('/event/:eid/stage/lineup/:id/edit', goToPage('Lineup','edit'));
		page('/event/:pid/stage/new', goToPage('EventStage','create'));
		page('/event/:pid/stage/:id/edit', goToPage('EventStage','edit'));
//		page('/event/:pid/stage/:id', goToPage('EventStage','view'));
		page('/event/new', goToPage('Event','create'));
		page('/event/:id/edit', goToPage('Event','edit'));
		page('/event/:id', goToPage('Event','view'));
		page('/diary/:day', goToPage('Diary'));
		page('/artists', goToPage('ArtistList',null));
		page('/artist/new', goToPage('Artist','create'));
		page('/artist/:id/edit', goToPage('Artist','edit'));
		page('/artist/:id', goToPage('Artist','view'));
		page('/badPermissions',goToMessagePage('badPermissions.njk'));
//TODO use goToMessagePage() + add return code
		//page('*', notFound); 
		page();
	})
	.catch(e => {
			console.log('DB error:',e);
		});

console.log('In index.js - 4');

function goToPage(pageName,mode)
{
	return (context,next) => {
console.log('In goToPage page:',pageName,'mode:',mode);
			const page = Pages.get(pageName);
			window.db.changeVisiblePage(page);
			page.changePage(context,next,mode);
			page.requireRender();
			pageUpdate(page.load(), page.display);
		};
}

function goToMessagePage(templateFileName)
{
	return (context,next) => {
        	const template = window.nunjucksEnv.getTemplate(templateFileName,true);
	        var str = template.render({});
     		document.getElementById('content').innerHTML = str;
//XXX POXY.  Its cancelling out the grey from any errors.  Should call a function in toPage.js
			document.getElementById('content').style.color = 'black';
console.log('Should be BLACK');
		};
}

//registerServiceWorker();  <-- Helps with caching when offline on on a slow connection
