import Query from './Query';
import PermissionException from './PermissionException';
import DbSelect from './DbSelect';

class UserListQuery extends Query
{
    constructor(db)
    {
        super('UserList',db);
    }

    structure()
    {
        return {Person:{}};
    }

	async run(user)
	{
		if (!user.amAdmin())
			throw new PermissionException("you lack administrator privileges");

		//TODO in time paginate  
		const query = new DbSelect(['id','firstName','lastName','email'],'Person');
		return await this.db.table(query,[]);
	}
}

export default UserListQuery;

