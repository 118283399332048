import PageParent from './PageParent';
import Form from './Form';
import EventArtistTable from './EventArtistTable';

class EventArtistPage extends PageParent
{
	constructor()
	{
		super('EventArtist','eventArtistPage.njk');
		this.form = new Form('form','#form',['EventArtist'],[]);
		this.addComponent(this.form);
	}

    changePage(context,next,mode)
    {
        this.eventId = Number(context.params.pid);
		this.form.changeIndex(mode,Number(context.params.id),[this.eventId]);
    }

    submit(e)
    {
        e.preventDefault();
        this.form.save().then(() => toPage('/event/'+this.eventId));
    }
}

//TODO need to disable delete & create AT SERVER LEVEL - requires associated records to be fixed up
//      THINK BY DEFAULT we should probably disable createRow() and deleteRow() in *Table() classes.
//          We could possibly provide some standard options, e.g. 
//           standardCreateRow(), isolatedCreateRow(), standaloneCreateRow(), shallowCreateRow(), simpleCreateRow()
//           shallowDeleteRow(), cascadeDeleteRow()  [but how to know where to end?]

export default EventArtistPage;
