import DbTable from './DbTable';
import Type from './DbFieldTypes';
import PersonTable from './PersonTable';

class CompanyPersonTable extends DbTable
{
    constructor(db)
    {
        super('CompanyPerson',db);
		this.owner = new PersonTable(db);

        this.myFields = new Map([
            ]);

		this.myServerOnlyFields = new Map([
                ['personId',  Type.id() ],
                ['companyId', Type.id() ] 
			]);
    }

	ownerTable()
	{
		//return DbTables.get('Person');
		return this.owner;
	}

    testData()
    {
        return  [
            {id:0,personId:0,companyId:5} 
        ];
    }
}

export default CompanyPersonTable;

