import EventTable from './EventTable';  //XXX maybe should only be on the server side?
import PageParent from './PageParent'; 
import Form from './Form';
import List from './List';
import DbQueries from './DbQueries';

class EventPage extends PageParent
{
//TODO if I delete an event and the event page is shown in another tab?
//TODO non-existent events shouldn't be shown
//XXX probably don't want back arrow taking us to the create page or edit page - confusing.  Also trim out any duplicate pages.  That might include A-B-A-B etc

//XXX if I visit one page then click create the form is populated with the last event values...

    constructor() 
	{
		super('Event','eventPage.njk');
        this.form = new Form('form','#form',['Event'],[]);
		this.list = new List('stages',DbQueries.get('EventLineups'));
		this.addComponent(this.form);
		this.addComponent(this.list);
	}

	changePage(context,next,mode) 
	{
		const id = Number(context.params.id);
console.log("EventPage.changePage id:",id,"mode:",mode);
		this.form.changeIndex(mode,id,[null]);
		this.list.changeIndex(null,id);
	}

    submit(e)
    {
        e.preventDefault();
        this.form.save().then(newId => toPage('/event/'+this.form.id(),null));
    }

    delete(e)
    {
        e.preventDefault();
//FIXME return to caller. Anything in page.js?
        this.form.delete().then(() => toPage('/',null) );
    }

    deleteEventStage(e,eventStageId)
    {
		e.preventDefault();
        db.startTransaction();
        db.deleteRow('EventStage',eventStageId);
        db.finishTransactionNoReturnValues();
        /* Using an optimistic update so resolve immediately: */
        return Promise.resolve();
    }

    deleteLineup(e,lineupId)
    {
		e.preventDefault();
        db.startTransaction();
        db.deleteRow('Lineup',lineupId);
        db.finishTransactionNoReturnValues();
        return Promise.resolve();
    }

    deleteSet(e,setId)
    {
		e.preventDefault();
        db.startTransaction();
        db.deleteRow('Set',setId);
        db.finishTransactionNoReturnValues();
        return Promise.resolve();
    }
}

export default EventPage;
