import Type from './DbFieldTypes';
import DbTable from './DbTable';
import DbTables from './DbTables';

class SetTable extends DbTable
{
    constructor(db)
    {
        super('Set',db);
//TODO initialise and cache fields in fields() instead
        this.myFields = new Map([
                ['position',        Type.positiveInteger() ],  //XXX will need to be consecutive and not 0
                ['start', 		    Type.dateTime().optional() ],
                ['end',        		Type.dateTime().optional() ],
            ]);
        this.myServerOnlyFields = new Map([
                ['lineupId',  		Type.id() ],
                ['eventArtistId',   Type.id().optional() ],
			]);

//TODO
// Adding a "set" doesnt update "eventArtistId". IDs are now server fields and ownerIDs 
// are included explicitly as extras in places like Form.getFormData().  Probably need to 
// add associations in a similar fashion.
    }

	ownerTable()
	{
		return DbTables.get('Lineup');
	}

	/*
		TRIALLING THIS METHOD.  MAY OR MAY NOT BE USED AT THE MOMENT.
		These are associations can be used to determine which joins are permitted. They
		should generally have indexes.
		Think of the Map's key as being the association name (wrt the current table).
		XXX probably should use EventArtist class and referredIdName() rather than strings.
	 */
//FIXME declare a stub in the parent
	associations() 
	{
		if (!this.hasOwnProperty('myAssociations'))
			this.myAssociations = new Map([
					["lineupId","Lineup"],
					["eventArtistId","EventArtist"]
				]);
		return this.myAssociations;
	}

    testData()
    {
        return  [
            //XXX in time allow for multiple stages
            //XXX maybe add notes? e.g. "solo", "DJ set", "with guest artist XXX"
            {id:0, lineupId:0, position:1, eventArtistId:2, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
            {id:1, lineupId:0, position:2, eventArtistId:1, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
            {id:2, lineupId:0, position:3, eventArtistId:0, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
            {id:3, lineupId:1, position:1, eventArtistId:1, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
            {id:4, lineupId:1, position:2, eventArtistId:2, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
            {id:5, lineupId:2, position:1, eventArtistId:1, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
            {id:6, lineupId:2, position:2, eventArtistId:2, start:new Date('2018-03-10T20:00:00'), end: new Date('2018-03-10T20:00:00') },
        ];
    }
}

export default SetTable;
