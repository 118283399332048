import Query from './Query.js';
import DbSelect from './DbSelect.js';

class CalendarQuery extends Query
{
    constructor(db)
    {
        super('Calendar',db);
    }

	// Return events by day and alerts by day for a month:
	async run(user)
	{
		const select = new DbSelect(['Event.id','name','start','end'],'Event')
			.innerJoin('Event.id','EventManager.eventId')
			.where('EventManager.managerId','=');

		var eventsByDay = new Map();
		await this.db.each(select,[user.managerId()], ev => {
				const day = (new Date(ev.start)).getDate();
				if (!eventsByDay.has(day))
					eventsByDay.set(day,[]);
				eventsByDay.get(day).push(ev);
			});

//XXX I'm not bothering at the moment to filter alerts by user

		const alertSelect = new DbSelect(['id','day'],'DayAlert')
			.where('managerId','=');

		var alertsByDay = new Map();
		await this.db.each(alertSelect,[user.managerId()], row => {
				const day = (new Date(row.start)).getDate();
				alertsByDay.set(row.day,row);
			});

		return { eventsByDay:eventsByDay, alertsByDay:alertsByDay };
	}
}

export default CalendarQuery;
