import page from 'page';


// There might be a chain of updates.  cf using a singleton so we can maintain one counter
// Complex if some updates are to a page, and others to sections

//XXX maybe the file shouldn't be called 'toPage'

//TODO make function name more consistent with toPage().  cf a scoping object
//  cf "asyncUpdate", "asyncPageUpdate", "modifyPage"

/*
	'loadPromise' can be null. In this case 'data' can be supplied.
 */
export function pageUpdate(loadPromise,updateFunc)
{
//XXX start transition.  MAY just affect part of a page
    document.getElementById('content').style.color = 'grey'; //XXX better to dim the while page (cf adding a class)
	loadPromise.then(d => {
			updateFunc(d);
            document.getElementById('content').style.color = 'black'; //TODO remove class (maybe put into router part)
		})
    .catch(err => {
console.log('pageUpdate() err:',err);
console.trace();
            if (err.errorType=='permission')
                alert('You lack the permissions');
            else
                alert('Operation failed');
            toPage('/',null);
        });
}

//XXX alts: changePage, newPage, turnPage,  loadPage
/*
	"promise" is optional.
	"urlOrFunc" needs to be a string if promise is omitted.
 */
export function toPage(urlOrFunc,promise) 
{
	if (typeof promise!='object' || promise==null)
	{
		//NOTE: could choose to put a transition here
		page(urlOrFunc);
		return;
	}

//XXX start transition
    document.getElementById('content').style.color = 'grey'; //XXX better to dim the while page (cf adding a class)

    promise.then(data => { 
            document.getElementById('content').style.color = 'black'; //TODO remove class (maybe put into router part)
            const url = typeof urlOrFunc == 'function' ? urlOrFunc(data) : urlOrFunc;
            page(url);
        });
//XXX else error.  Undo dimming

}

window.toPage = toPage;  //XXX is some scoping possible with these globals?

//TODO use in tempates too instead of href!
