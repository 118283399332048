/* 
	Note: various other argon libraries exist should this one prove a problem.
 */

import crypto from 'crypto';
var argon2d = require('argon2-ffi').argon2d; 

export function verifyPassword(password,hash) 
{
	/* 
		"hash" appears to include the salt hidden in it somewhere, 
		together with the encryption options which are easy to see. 

		argon-ffi throws an exception if the hash format is not recognised.
		For the moment I'm just treating this as an incorrect password.
	 */
	return new Promise((resolve,reject) => {
			argon2d.verify(hash,password)
				.then(correct => resolve(correct))
				.catch(() => resolve(false))  //TODO possibly save into log as bad format
		});
}

export function newPasswordHash(password)  
{
	return new Promise((resolve,reject) => {

		crypto.randomBytes(32, (err,salt) => {
				if (err) 
					throw err;  //TODO probably should reject call

				let options = { timeCost: 4, memoryCost: 1 << 14, parallelism: 2, hashLength: 64 };
				argon2d.hash(password,salt,options).then( hash => {
							resolve(hash);
						});
			});
		});
}

/* Used to create the hashes in UserTable.testData() */
export function createTestPasswordHash(password)
{
	newPasswordHash(password).then( hash => console.log(hash));
}
