import Query from './Query';
import DbTables from './DbTables';

class CompanyListQuery extends Query
{
    constructor(db)
    {
        super('CompanyList',db);
    }

	async run(user)
	{
		const table = DbTables.get('Company');
		const query = table.ownedRowsQuery(user).fields(['Company.id','name']);
        const ret = [];
		for (let i of await this.db.table(query,[]))
			ret.push(i);
		return ret;
	}

	structure()
	{
		return {Company:{}};
	}
}

export default CompanyListQuery; 
