import ConnectionToServer from './ConnectionToServer';
import DbTables from './DbTables';
import DbQueries from './DbQueries';
import DbId from './DbId';
import io from 'socket.io-client';

/* 
	Using Typeson to help serialise data structures for socket.io
	I think it is possible to reduce the size of the .js that needs to be included by
	using a "rollup" - see https://github.com/dfahlander/typeson-registry
		I guess they mean to use this: https://rollupjs.org
	Note that there is also:  https://github.com/dfahlander/typeson

	FURTHER NOTES: rollup is a bit of a pain.  Could try Webkit's tree shaking
 */

const Typeson = require('typeson-registry/dist/all.js');
//    presetSocketIo = require('typeson-registry/presets/socketio.js');
const {presets: {builtin}} = Typeson;
const TSON = new Typeson().register([ builtin ]).register({
		DbId: DbId
	});


class SocketIoConnectionToServer extends ConnectionToServer
{
    init() 
	{
        DbTables.init(null);  //TODO should only distribute to browsers the client-friendly properties of Tables 
        DbQueries.init(null); //XXX do we need/want the Query model browser-side?

		//this.socket = io.connect('http://localhost:8080');

		const match = location.href.match(/^https?:\/\/[^/]+/);
		this.socket = io.connect(match[0]);

		this.socket.on('dbChange', data => {
				this.handleDbUpdates(false,TSON.revive(data.newValue));
			});
	} 

//FIXME NOTE  finishTransactionNoReturnValues() probably shouldn't be waiting here...
    slowPerformTransaction(transaction) 
	{
		return new Promise((resolve,reject) => {
			const str = TSON.encapsulate(transaction);
			this.socket.emit('TRANSACTION', str, 
                    ret => this.handleReturn(resolve,reject,ret));
				});
	} 

    readRow(rootTable,rootId,joins) 
	{
		return new Promise((resolve,reject) => {
                this.socket.emit('READ_ROW', {table:rootTable,id:rootId,joins:joins}, 
                        ret => this.handleReturn(resolve,reject,ret));
            });
	}

    query(queryName,params) 
	{
		return new Promise((resolve,reject) => {
			    this.socket.emit('QUERY', {name:queryName,params:params}, 
                        ret => this.handleReturn(resolve,reject,ret));
			});
	}

	misc(operation,params)
	{
		return new Promise((resolve,reject) => {
			    this.socket.emit(operation,params,ret => this.handleReturn(resolve,reject,ret));
			});
	}

    handleReturn(resolve,reject,ret)
    {
        const data = TSON.revive(ret);
console.log('handleReturn  data:',data);
        if ('errorType' in data)
            return reject(data);
        resolve(data);
    }
}

export default SocketIoConnectionToServer;
