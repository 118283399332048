/*
	All pages to be displayed should extend this class and replace the function prototypes below.

	The router makes these calls in the following order:
		changePage()
		load()
		display()

	Typically a page will use addComponent() in its constructor to add one or more Components.
	The components will generally be in control of their own caching.
	
	Typically a page should be created once and not destroyed.  This allows the template to be loaded
	just the once.

	ALTERNATIVES TO morphdom:
	* https://github.com/fiduswriter/diffDOM
	* https://github.com/choojs/nanomorph
	* https://www.npmjs.com/package/apply-html/v/1.0.0-15
	* https://github.com/skatejs/dom-diff ?
 */

import morphdom from 'morphdom';

class PageParent
{
	constructor(name,templateName)
	{
        this.myName = name;
		this.templateName = templateName;
		this.template = null;
		this.components = [];
		this.display = this.display.bind(this);
		this.handleDbUpdates = this.handleDbUpdates.bind(this);
		this.leavingPage = false;

		/* 
			'isFirstDisplay' is set to true in load() and false at the end of display().
			If the page is left and revisited it will be set back to true by load().
		 */ 
		this.isFirstDisplay = true;
	}

	name()
	{
		return this.myName;
	}

	addComponent(component)
	{
		this.components.push(component);
	}

	/* Stores the page parameters. This is called before "load()".  */
    changePage(context,next,mode)
    {
    }

    getDisplayData()
    {
		let env = {
            person: window.person,
            imagesUrl: window.imagesUrl,
            assetsUrl: window.assetsUrl,
        };
	
		if (!this.isFirstDisplay)
			this.copyInputsToData();

        const data = {};
		for (const c of this.components)
			data[c.instanceName] = c.getDisplayData(env);

        return {...env, ...data};
    }

    display()
    {
		if (this.leavingPage)
			return;

		/* Compile the template on first use */
		if (this.template==null)
			this.template = window.nunjucksEnv.getTemplate(this.templateName,true);

        const data = this.getDisplayData();

		let str = null;
		try {
        	str = this.template.render(data);
		}
		catch(e)
		{
			console.log('nunjucks error:',e);
		}

		const content = document.getElementById('content');

		if (this.isFirstDisplay)
			content.innerHTML = str;
		else
		{
			const e = document.createElement('div');
			e.innerHTML = str;
console.log('Before morphdom');
			morphdom(content,e,this.displayDiffOptions());
console.log('After morphdom');
			content.id = 'content';
		}

		this.isFirstDisplay = false;
	}

	/* Options used in the DOM-diffing by MorphDOM */
	displayDiffOptions()
	{
		return {};
	}

	/* Loads state into the component objects. Returns a promise. */
	load() 
	{
		this.isFirstDisplay = true;
		const promises = [];
		for (let c of this.components)
			promises.push(c.load());
		return Promise.all(promises);
	}

	/* 
		When an update is sent from the server ensure this page is correctly synchronised.
		Note updates will not be received here when they have been triggered from the same page 
		in the same browser window.
	 */
    handleDbUpdates(isVisible,updates)
    {
		let changedData = false;
		for (let c of this.components)
			for (let update of updates)
				if (c.handleDbUpdate(update.table,update.operation,update.id,update.row))
					changedData = true;

        if (isVisible && changedData)
            this.display(); 
	}

	copyInputsToData()
	{
		for (let c of this.components)
			c.copyInputsToData();
	}

	/* 
		Call this before a DB operation to prevent an unnecessary render.
		XXX Not that fond of this solution as its easy to forget.
	*/
	setLeavingPage()
	{
		this.leavingPage = true;
	}
	
	requireRender()
	{
		this.leavingPage = false;
	}
}

export default PageParent;
