import DbTable from './DbTable';
import DbSelect from './DbSelect';
import DbInsert from './DbInsert';
import Type from './DbFieldTypes';
import User from './User';

class PersonTable extends DbTable
{
    constructor(db)
    {
        super('Person',db);
        this.myFields = new Map([
//TODO index fbIdentifier, googleIdentifier, email

                ['firstName',  Type.string() ],
                ['lastName',   Type.string() ],
//XXX doubt that email should really be public here
                ['email',     Type.email().optional() ]
            ]);

//XXX Might be handy to have "amAdmin" in client. Also would be nice to hide admin-related code from non-admins
//XXX might be slightly safer to have the first users not be admins.

//XXX in time maybe have a separate password table

		this.myServerOnlyFields = new Map([
				['amAdmin',       Type.boolean() ],
                ['managerId',     Type.id() ], 
                ['hash',          Type.string().optional() ],
                ['hashUse',       Type.dateTime().optional() ],
				/* Two old passwords are stored, together with the time of last use */
                ['lastHash1',     Type.string().optional() ],
                ['lastHash1Use',  Type.dateTime().optional() ],
                ['lastHash2',     Type.string().optional() ],
                ['lastHash2Use',  Type.dateTime().optional() ],
                ['fbIdentifier',  Type.string().optional() ],
                ['googleIdentifier',  Type.string().optional() ],
				/* When an email gets added or changed we need to confirm it before use */
                ['confirmingEmail',   Type.email().optional() ],

				/* Could be useful for monitoring fake and/or dead accounts: */
                ['creationTime',  Type.dateTime().optional() ],
                ['creationIp',    Type.string().optional() ],
//NB include FB and Google in this...
                ['lastSuccessfulLogin', Type.dateTime().optional() ],
//Do FB and/or Google provide these?
                ['lastSuccessfulIp',    Type.string().optional() ],

				/* Could be useful for monitoring hacking: */
                ['lastUnsuccessfulIp',  Type.string().optional() ],

				/* Limit number of attempted logins: */
                ['loginThrottleCount',  Type.positiveInteger().optional() ],
					// Same as lastUnsuccessfulLogin
                ['loginThrottleTime',   Type.dateTime().optional() ],
			]);
    }

	testDataEntry(id,amAdmin,managerId,first,last,email)
	{
		/* To generate these hashes in the 'server' directory run:  "node createPassword.js" */
		/* A hash for 'ben' */
		const hash = '$argon2d$v=19$m=16384,t=4,p=2$zi5ByMyVItqPqCMGYX6KPqmU9+mXkIDatt+4lpn/1+k$Czk2oBzthLPK2eyf4IPgZ9mTmJkjuXfhcmJ/UlyGhHIVGMoZh3H/kEdz6fnOLLmtcIzea2WTPN7n0uFJNO+Dmw';
		const now = new Date();
		const ip = '0.0.0.0';

		return {
				id:id,managerId:managerId,amAdmin:amAdmin,firstName:first,lastName:last,email:email,
				hash:hash,hashUse:now,lastHash1:null,lastHash1Use:null,lastHash2:null,lastHash2Use:null,
				fbIdentifier:null,googleIdentifier:null,confirmingEmail:null,
				creationTime:now,creationIp:ip,lastSuccessfulLogin:now,lastSuccessfulIp:ip,
				lastUnsuccessfulIp:ip,loginThrottleCount:0,loginThrottleTime:now
			};
	}

    testData()
    {
        return  [
				this.testDataEntry(0,false,1,'Shane','Jacobson','shanejacobson@portaloo.com.qqq'),
				this.testDataEntry(1,false,1,'Jake','Shandon','jake.shandon@gmail.com.qqq'),
				this.testDataEntry(2,false,1,'Sonny','Jackson','sonny777@hotmail.com.qqq'),
				this.testDataEntry(3,true,1,'Addy','Admin','admin@ontoitmedia.xyz') 
			];
	}
    
	amPermissionRoot()
	{
		return true;
	}

    async havePermission(user,opType,id,column)
    {
		switch(opType)
		{
			case 'CREATE_ROW':
			case 'DELETE_ROW':
				return user.amAdmin();
// - e.g. Name can be viewed and updated
			case 'UPDATE_ROW':
			case 'READ_ROW':
				return id==user.personId() || user.amAdmin();  
		}
		throw new Error('Unsupported operation: '+opType);
    }

	async adjustReadRowQuery(query)
	{
		return query.field('Person.googleIdentifier').field('Person.fbIdentifier').field('Person.hash');
	}

	async adjustReadRowResults(rawRow,convRow)
	{
		convRow.Person.googleConnected = rawRow.googleIdentifier!=null;
		convRow.Person.fbConnected = rawRow.fbIdentifier!=null;
		convRow.Person.havePassword = rawRow.hash!=null;
		return convRow;
	}

	/* Returns a promise on success.  */
	async registerNewPerson(thirdPartyAuth,firstName,lastName,email,password,ip,amAdmin,googleId,facebookId)
	{
		/* Validate fields: */
        const error1 = Type.email().validate(email);
        if (error1!=null)
            return Promise.reject({field:'email2',msg:error1}); 

        if (!thirdPartyAuth)
        {
            const error2 = Type.password().validate(password);
            if (error2!=null)
                return Promise.reject({field:'password',msg:error2}); 
        }

//XXX can I guarantee these names exist when registering via 3rd parties?
        if (!thirdPartyAuth && (firstName==null || firstName.trim()==''))
            return Promise.reject({field:'firstname2',msg:'First name is required'}); 
        if (!thirdPartyAuth && (lastName==null || lastName.trim()==''))
            return Promise.reject({field:'lastname2',msg:'Last name is required'}); 

		/* Clean up the names: */
		firstName = typeof firstName=='string' ? firstName.trim() : '';
		lastName = typeof lastName=='string' ? lastName.trim() : '';

		/* Has the email been taken? */
		const select = new DbSelect(['id'],'Person').where('email','=');
		if (await this.db.cell(select,[email]) != null)
			return Promise.reject({field:'email2',msg:'email already exists'}); 

		/* Create a Manager entry for the person: */
		const insert1 = new DbInsert('Manager');
		const managerId = await this.db.insert(insert1,[]);

console.log('PersonTable.registerNewPerson()  managerId:',managerId);

		/* Create the row in Person: */
		const insert2 = new DbInsert('Person').setToValue('managerId').setToValue('firstName')
			.setToValue('lastName').setToValue('email').setToValue('hash')
			.setToValue('hashUse').setToValue('creationTime').setToValue('creationIp')
			.setToValue('lastSuccessfulLogin').setToValue('lastSuccessfulIp')
			.setToValue('loginThrottleCount').setToValue('amAdmin')
			.setToValue('googleIdentifier').setToValue('fbIdentifier');

		const now = (new Date()).toISOString();

		if (password==null)
		{
			/* Handle third-party regos that don't have passwords: */
			const personId = await this.db.insert(insert2,
					[managerId,firstName,lastName,email,null,now,now,ip,now,ip,0,amAdmin,googleId,facebookId]);
			return Promise.resolve(new User(email,personId,managerId,false));
		}

		const newPasswordHash = require('./server/password').newPasswordHash;

		return newPasswordHash(password).then(async hash => {
				const personId = await this.db.insert(insert2,
					[managerId,firstName,lastName,email,hash,now,now,ip,now,ip,0,0,googleId,facebookId]);
				return new User(email,personId,managerId,false);
			});
	}
}

export default PersonTable;

